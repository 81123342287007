@import "./../../../Theme/Components/Colors";
@import "./../../../Theme/Components/Mixins";

#Global-Return-Panel {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 2.8125rem;
  justify-content: space-between;
  background: $white;
  z-index: 90;
  border-bottom: 1px solid $grey-200;

  .title-content {
    width: 100%;
    font-size: 1rem;
    color: $grey-800;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include breakpoint(medium) {
      font-size: 1.25rem;
    }
  }
}
