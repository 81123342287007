@import "./../../../../Theme/Components/All";

.product-details-cart {
  width: 100%;
  border-top: 1px solid $grey-200;
  color: $grey-700;
  background-color: #ffffff;
  border-radius: 4px;
  display: none;
  @include breakpoint(medium) {
    display: block;
  }

  .product-details-cart-container {
    display: flex;
    justify-content: space-between;
    font-family: $calibre-font-face;
    border-bottom: 1px solid $grey-200;
    padding: 0.3125rem 0.5rem;

    & > .cart-label {
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
      align-self: center;

      &.items {
        letter-spacing: 1px;
        line-height: 1.4;
      }
    }

    & > .cart-value {
      font-size: 0.875rem;
      color: $cin7-goback-label;

      &.discount {
        color: $cin7-b2b-discount-label;
      }

      &.items {
        color: $cin7-b2b-items-label;
      }
    }
  }
}
