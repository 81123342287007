@import "./../../../Theme/Components/All";

#Account-Details-Container {
  display: flex;
  justify-content: center;

  .account-details-content {
    width: 100%;
    margin: 1rem 0.625rem;
    background-color: $white;
    border: 1px solid $grey-200;
    border-radius: 4px;
    padding: 0.625rem;
    font-size: 0.875rem;
    overflow-x: auto;

    @include breakpoint(small) {
      margin: 1rem;
      padding: 1rem;
    }

    @include breakpoint(medium) {
      max-width: 740px;
      margin: 1.5rem;
      padding: 2.5rem;
    }

    @include breakpoint(large) {
      max-width: 1250px;
    }

    .account-on-hold-container {
      .account-on-hold-banner {
        margin-bottom: 1.2rem;
      }
    }

    .section-heading {
      text-transform: uppercase;
      font-weight: bold;
      border-bottom: 1px solid $grey-700;
      padding: 0.5rem 0;
      font-size: 0.75rem;
    }

    .section-note {
      font-size: 0.625rem;
      color: $grey-500;
      padding: 0.5rem 0;
    }

    .user-details-container {
      display: flex;
      justify-content: space-between;

      .user-details {
        .user-detail {
          line-height: 1.4375rem;

          &.user-name {
            font-weight: bold;
          }
        }
      }

      .user-details-action {
        .log-out-link {
          color: $cin7-sku-blue-label;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .order-history-link {
      margin-top: 1rem;
      padding: 1rem 0;

      a {
        color: $cin7-sku-blue-label;
      }
    }
  }
}
