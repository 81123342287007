@import "../../../../Theme/Components/All";

.side-image-theme-footer {
  float: left;
  clear: both;
  width: 100%;
  margin: 3.125rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  @include breakpoint(medium) {
    display: none;
    visibility: hidden;
  }

  &.text-logo {
    margin: 0.75rem 0 0;
    @include breakpoint(medium) {
      display: flex;
      visibility: visible;
    }
  }

  &.no-logo {
    @include breakpoint(medium) {
      display: flex;
      visibility: visible;
    }

    .side-image-theme-footer-logo {
      margin: 5rem 0 0;

      @include breakpoint(medium) {
        display: none;
        visibility: hidden;
      }
    }
  }

  .side-image-theme-footer-logo {
    float: left;
    clear: both;
    width: 100%;
    max-width: 118px;
    font-size: 0.75rem;

    .cin7-text-logo a {
      color: $cin7-blue;
    }
  }
}
