@import "./../../../../Theme/Components/All";

.product-details-teaser {
  width: 100%;
  font-family: $calibre-font-face;
  color: $cin7-b2b-product-teaser-text;
  font-size: 0.875rem;
  line-height: 1.25;
  padding-bottom: 1rem;
}

.showMore {
  cursor: pointer;
  color: #00326b;
  font-weight: 600;
  margin-top: 0.5rem;
}