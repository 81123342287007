.add-note-button {
  display: inline-block;
  cursor: pointer;
  color: #004FA9 !important;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
    text-decoration-color: #004FA9;
  }
}
