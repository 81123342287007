@import "./../../../../Theme/Components/All";

.option-details-inner-child-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button.option-quantity-actions {
    padding: 5px;
    background: transparent;
    border: none;

    &:focus {
      outline: none;
    }

    svg {
      width: 30px;

      @media (max-width: 400px) {
        width: 27px;
      }
    }

    svg rect,
    svg path {
      @include global-transition($global-float-label-transition-medium);
    }

    &:disabled {
      svg rect,
      svg path {
        fill: $grey-400 !important;
      }
    }

    @media (hover: hover) {
      &:not(:disabled) {
        cursor: pointer;

        &:hover svg rect,
        &:hover svg path {
          fill: $cin7-b2b-items-label !important;
        }
      }
    }

    &:not(:disabled) {
      &:active svg rect,
      &:active svg path {
        fill: $black !important;
      }
    }
  }

  .option-quantity {
    font-size: 1rem;
    font-weight: 500;
    font-family: $calibre-font-face;
    padding: 0 0.625rem;
    color: $theme-primary;
    border: 1px solid #B7B7B7;
    border-radius: 4px;
    max-width: 3.5rem;
    text-align: center;
    height: 2.5rem;

    @include breakpoint(medium) {
      font-size: 1.2rem;
      max-width: 4.625rem;
      margin: 0 1rem;
    }
  }
}
