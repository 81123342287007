@import "../../../../Theme/Components/All";

#Order-Review-Details-List {

  .product-options-container {
    width: 100%;

    &.multiple-options {

      .product-options-inner-container:not(:last-child) {
        border-bottom: 2px solid #DCDCDC;

        @include breakpoint(large) {
          border-width: 1px;
        }
      }
    }

    .option-title {
      color: $black;
      font-size: 1.125rem;
      font-weight: 600;
      border-top: 2px solid #DCDCDC;
      padding: 0.5rem 1rem;

      @include breakpoint(large) {
        padding: 0.5rem 0;
      }
    }

    .option-header {
      display: flex;
      flex-direction: column;
      border-bottom: 2px solid #DCDCDC;

      @include breakpoint(large) {
        border-bottom-width: 1px;
        margin: 0 1rem;
      }

      &.has-notes {
        display: block;
      }

      .option-note {
        padding: 0.5rem 1rem;

        @include breakpoint(medium) {
          display: grid;
          grid-template-columns: 90px auto;
          grid-template-areas: "AddNoteButtonContainer NoteContainer";
          align-items: center;
        }

        @include breakpoint(large) {
          padding: 0.5rem 0;
        }

        .note-container {
          grid-area: NoteContainer;
        }

        .add-note-button-container {
          grid-area: AddNoteButtonContainer;
          font-size: 0.875rem;
        }
      }
    }

    .option-header-titles {
      display: none;
      color: #777777;
      border-bottom: 1px solid #E0E0E0;
      padding: 0.375rem 0;
      font-size: 0.875rem;

      @include breakpoint(large) {
        display: grid;
        grid-template-columns: 2fr 1fr 3fr 3fr 30px;
        grid-template-areas:
          ". . Quantity Price .";
        margin: 0 1rem;
      }

      .title-quantity {
        grid-area: Quantity;
        justify-self: center;
      }

      .title-price {
        grid-area: Price;
        display: grid;
        justify-items: end;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    .product-options-inner-container {
      padding: 0 1rem;
      display: grid;
      grid-template-columns: auto 25px;
      grid-template-areas:
        "Label Action"
        "Note Note"
        "Quantity Quantity"
        "Totals Totals";

      @include breakpoint(medium) {
        grid-template-columns: 1fr 2fr 3fr 25px;
        grid-template-areas:
          "Label Label Quantity Action"
          "AddNote Note Note ."
          "Totals Totals Totals .";
      }

      @include breakpoint(large) {
        grid-template-columns: 2fr 1fr 3fr 3fr 30px;
        grid-template-areas:
          "Label AddNote Quantity Totals Action"
          ". Note Note Note .";
        margin: 0 1rem;
        padding: 0;
      }

      .option-label {
        grid-area: Label;
        color: $black;
        padding: 0.625rem 0;
        display: flex;
        align-items: center;

        @include breakpoint(medium) {
          border-bottom: 2px solid #F2F2F2;
        }

        @include breakpoint(large) {
          border: none;
        }
      }

      .option-action-container {
        grid-area: Action;
        text-align: right;
        align-self: center;

        .product-cart-delete:hover {
          cursor: pointer;
        }
      }

      .add-note-button-mobile {
        font-size: 0.875rem;

        @include breakpoint(medium) {
          display: none;
        }
      }

      .add-note-button-desktop {
        grid-area: AddNote;
        display: none;
        font-size: 0.875rem;

        @include breakpoint(medium) {
          display: initial;
          display: flex;
          align-items: center;
        }
      }

      .option-note {
        grid-area: Note;
        padding-bottom: 0.5rem;

        @include breakpoint(medium) {
          padding: 0;
          display: flex;

          &.has-notes {
            margin: 0.5rem 0;
          }
        }
      }

      .option-quantity-container {
        grid-area: Quantity;
        font-size: 0.875rem;
        padding: 1rem 0;
        border-top: 2px solid #F2F2F2;
        border-bottom: 2px solid #F2F2F2;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        @include breakpoint(medium) {
          border-top: none;
          padding: 0.5rem 0;
        }

        @include breakpoint(large) {
          border: none;
          padding: 0.25rem 0;
        }

        .quantity-label {
          color: #777777;
          font-size: 0.875rem;
          padding-bottom: 0.25rem;

          @include breakpoint(large) {
            display: none;
          }
        }
      }

      .option-price-container {
        grid-area: Totals;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 1rem 0;

        @include breakpoint(large) {
          text-align: right;
          padding: 0.25rem 0;
        }

        .option-price {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .product-details-option-price {
            display: flex;
            flex-direction: column;
            color: $black;
            font-size: 1rem;
          }          
        }

        .price-value-label {
          color: #777777;
          font-size: 0.875rem;

          @include breakpoint(large) {
            display: none;
          }
        }

        .option-UOM-unit-price {
          font-size: 0.8rem;
          font-weight: 400;
          display: -webkit-box;
          margin-left: auto;
        }

        .option-discount {
          display: flex;
          flex-direction: column;
          justify-content: center;

          &.discount-applied {
            color: $cin7-b2b-discount-label;
          }
        }

        .option-total {
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: $black;
          text-align: right;
        }
      }
    }

    .product-options-total-container {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-areas: "Items Total";
      padding: 0.5rem 1rem;

      @include breakpoint(large) {
        grid-template-columns: auto auto 30px;
        grid-template-areas:
          "Items Total .";
        margin: 0 1rem;
        padding: 0.5rem 0;
      }

      .product-total-items {
        grid-area: Items;
      }

      .product-total-price {
        grid-area: Total;
        text-align: right;
      }
    }
  }
}
