@import "./../../../Theme/Components/All";

.main-header {
  background: $white;
  z-index: 200;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 45% 1fr 1.1fr;
  grid-template-areas: "Menu Logo Search Cart";
  max-height: 80px;
  border-bottom: 1px solid $grey-400;
  @include global-transition($global-side-nav-transition);
  font-size: 1.125rem;
  top: 0;
  position: fixed;

  @include breakpoint(medium) {
    grid-template-columns: 80px 130px auto 100px 250px;
    grid-template-areas: "Menu Search Logo Tax Cart";
  }

  @include breakpoint(large) {
    grid-template-columns: 80px 140px auto 120px 300px;
    grid-template-areas: "Menu Search Logo Tax Cart";
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ styles */
    display: flex;
    align-items: center;
  }

  .nav-menu-toggle-container {
    grid-area: Menu;
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid $grey-400;

    .menu-button {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 48px;
        height: 48px;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ styles */
        width: 120px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .search-container {
    grid-area: Search;
    border-left: 1px solid $grey-400;
    border-right: 1px solid $grey-400;

    @include breakpoint(medium) {
      border-left: none;
    }

    @include breakpoint(large) {
      border-right: none;
    }
  }

  .logo-container {
    grid-area: Logo;
    width: 100%;
    height: 80px;
    margin: 0 auto;
    position: relative;

    .company-logo {
      position: relative;
      max-width: 175px;
      max-height: 80px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      margin: auto;

      @include breakpoint(medium) {
        max-width: 250px;
      }

      a.logo-link {
        width: 100%;
        height: 100%;
        outline: none;
      }

      img {
        padding: 0.3125rem;
      }

      svg {
        max-height: 80px;
      }
    }

    .company-store-name {
      position: relative;
      max-width: 175px;
      max-height: 80px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      margin: auto;

      @include breakpoint(medium) {
        max-width: 500px;
      }

      a.logo-link-store-name {
        width: 100%;
        height: 100%;
        outline: none;
        display: flex;
        align-items: center;
      }

      .store-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        font-size: 2.1rem;
        line-height: 1.2;
        font-weight: 500;
        color: $navy;
      }
    }
  }


  .tax-container {
    grid-area: Tax;
    height: 100%;
    border-left: 1px solid $grey-400;
    border-right: 1px solid $grey-400;
    display: none;
    visibility: hidden;

    @include breakpoint(medium) {
      display: block;
      visibility: visible;
    }
  }

  .cart-container {
    grid-area: Cart;
    width: 100%;
    height: 100%;
    display: flex;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ styles */
      max-width: 500px;
    }

    .mini-cart-container {
      width: 100%;

      .cart-link {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 2fr 4fr;
        grid-template-areas: "MobileQuantity" "CartIcon";
        justify-items: center;

        @include breakpoint(medium) {
          grid-template-rows: auto;
          grid-template-columns: 1fr 4fr 1fr;
          grid-template-areas: "CartIcon CartQuantityTotal CartArrowIcon";
          justify-items: initial;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          /* IE10+ styles */
          display: flex;
          align-items: center;
          justify-content: space-around;
        }

        .mini-cart-order-quantity {
          grid-area: MobileQuantity;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          position: relative;
          max-width: 88px;
          min-width: 68px;
          font-size: 0.725rem;
          letter-spacing: 1.2px;
          margin: 0.4rem 0;
          margin-bottom: 0;
          color: $black;
          background-color: $cin7-mini-cart-order-background;
          @include border-radius(0.75rem);

          &::after {
            content: "";
            position: absolute;
            bottom: -0.3125rem;
            left: 50%;
            width: 0;
            height: 0;
            border-left: 0.3125rem solid transparent;
            border-right: 0.3125rem solid transparent;
            border-top: 0.3125rem solid $cin7-mini-cart-order-background;
            @include global-transform(translateX(-50%));
          }

          @include breakpoint(medium) {
            display: none;
          }
        }

        .cart-icon-container {
          grid-area: CartIcon;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .cart-order-quantity {
          grid-area: CartQuantityTotal;
          display: none;
          color: #00326b;
          padding-left: 0.5rem;
          line-height: 1.5rem;

          @include breakpoint(medium) {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }

    .cart-arrow-icon-container {
      grid-area: CartArrowIcon;
      justify-content: center;
      align-items: center;
      display: none;

      @include breakpoint(medium) {
        display: flex;
      }

      i.icon {
        font: normal normal normal 14px/1 FontAwesome,sans-serif;
        font-size: 24px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        color: $theme-primary;
        padding-right: 0.5rem;
      }
    }
  }
}
