//Global Fonts
@import "./Components/Fonts";
//Reset Values
html {
  font-size: 100%;
  font-family: $calibre-font-face;
  overflow-x: hidden;
}

html,
body {
  height: 100%;
  position: relative;
  font-family: "Calibre", sans-serif;
  overflow: hidden; // <body> scrolls on iPhone without this
}

//Imports
@import "./Components/Colors";
@import "./Components/Mixins";
@import "./Components/Animations";
@import "./Components/Forms";

body {
  background: $white;
}

//Layouts
@import "./Layout/Master";

//Links
a,
a:link,
a:visited,
a:active {
  color: $navy;
  text-decoration: none;
}

//Global
.container {
  height: 100%;
}

.clear-space {
  clear: both;
}

#b2b-root {
  float: left;
  clear: both;
  width: 100%;
  height: 100%;

  .main-wrapper {
    float: left;
    clear: both;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

label {
  margin-bottom: 0;
}

.email-display {
  color: $cin7-default-font-color;
  text-transform: lowercase;
}
