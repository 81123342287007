@import "../../../../Theme/Components/All";

.add-note-modal {
  &__action-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;

    :not(:last-child) {
      margin-bottom: 0.875rem;
    }
  }

  &__notes {
    margin-bottom: 0.875rem;
    resize: none;
    outline: none;
    width: 100%;
    height: 8rem;

    @include breakpoint(large) {
      height: 10rem;
    }
  }

  &__content > :not(:last-child) {
    margin-bottom: 1rem;
  }
}
