@import '../../../../Theme/Components/All';

.product-promotions-section-wrapper {

    & .MuiPaper-root, & .MuiCollapse-container, & .MuiCollapse-entered {
        margin-bottom: 15px;
        @include global-transition(all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms);
    }

    & .MuiAccordionSummary-content:not(.Mui-expanded) {

        & > .MuiTypography-root {
            color: $c21;
        }
    }

    & .MuiAccordionDetails-root {
        padding: 0px 16px 16px;
    }
}