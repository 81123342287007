@import "./../../../../Theme/Components/All";

#Products-List-Container {
  .product-tile {
    width: 100%;
    display: flex;
    flex-grow: 1;

    .product-link {
      display: flex;
      padding: 0.5rem;
      width: 100%;
      height: 100%;
      border: 1px solid $white;
      border-radius: 12px;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        border: 1px solid $cin7-b2b-items-label;
        outline: none;
      }
    }

    .product-tile-inner {
      display: flex;
      align-items: center;
      flex-grow: 1;
      margin: 7px;
      background-color: $white;
      color: $black;
      box-shadow: 0px 0px 40px -10px rgba(0, 0, 0, 0.08), 4px 4px 4px 0px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      position: relative;

      a:hover {
        text-decoration: none;
      }

      .product-image-container {
        padding: 0 5px;
        width: 68px;
        flex-shrink: 0;
        position: relative;
      }

      .product-detail-container {
        padding: 0 10px;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;

        .product-title {
          font-size: 1.25rem;
          color: #373839;
          font-family: $calibre-font-face;
          letter-spacing: 0px;
          line-height: 1.25;
          font-weight: 500;
          word-break: break-word;
          margin-bottom: auto;
          margin-top: auto;
        }

        .product-code {
          display: block;
          font-size: 0.8rem;
          color: #777;
        }

        .product-stock-option-container {
          display: flex;

          .stock-indicator ~ .product-options, .stock-indicator-container ~ .product-options {
            margin-left: 0.5rem;
          }

          .product-options {
            font-size: 1rem;
            color: $cin7-blue;
            font-weight: 500;
            line-height: 1.5rem;
            display: flex;
            align-items: flex-end;
            justify-content: center;
          }
        }
      }

      .product-action-container {
        display: flex;
        justify-content: flex-end;
        margin: 0.625rem auto;

        .product-arrow-right {
          align-self: center;
          @include global-transition($global-float-label-transition-medium);

          @include hover-supported() {
            &:hover {
              @include global-transform(translateX(7px));
            }
          }

          &:active {
            filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.67));
          }
        }
      }
    }
  }

  .gallery-view {
    .product-tile {
      text-align: left;

      .product-link {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
      }

      .product-tile-inner {
        .product-image-container {
          width: 100%;
          height: 200px;
          padding: 0 5px 0 0;
        }

        .product-detail-container {
          padding-top: 0.5rem;

          .product-title {
            height: auto;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0;

            .title-md {
              font-size: 0.875rem;
            }

            .title-lg {
              font-size: 0.75rem;
            }
          }

          .product-price {
            .product-details-price {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
            }
          }

          .product-stock-option-container {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            height: 100%;

            .product-options {
              margin: 0;
            }
          }
        }

        .product-action-container {
          display: none;
        }
      }
    }
  }
}
