@import "../../../../Theme/Components/All";

.promotions-list-container {
  max-height: 12rem;
  overflow-y: auto;
  width: 100%;
  font-size: 0.875rem;

  & > .contract-pricing-container {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0rem;
    border-bottom: 0.8px solid #eaeaea;

    & > .header {
      font-size: 1rem;
      font-weight: 600;
      padding-bottom: 0;
    }
  }
}
