@import "../../../../Theme/Components/All";

#Delivery-Instructions-Panel {
  color: $black;
  margin-top: 1.5rem;

  .date-picker-clear-icon {
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    margin-right: 0.25rem;
  }

  .panel-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    font-size: 0.875rem;

    .panel-content-group {
      float: left;
      clear: both;
      width: 100%;

      &.delivery-date {
        margin-bottom: 1.5rem;
      }

      .panel-group-label {
        margin-bottom: 0.5rem;
        font-weight: bold;
      }

      .panel-group-field {
        float: left;
        clear: both;
        width: 100%;
      }
    }

    textarea {
      width: 100%;
      border: 1px solid $c37;
      @include border-radius(4px);
      min-height: 3.125rem;
      max-height: 9.375rem;
      outline: none;
    }
  }
}

// semantic date picker styles - can't use semantic react css as it clashes with UI library material UI
#Delivery-Instructions-Panel {
  .clndr-calendars-segment.clndr-top {
    box-shadow: 0 -1px 2px 0 rgba(34, 36, 38, 0.15) !important;
    margin-bottom: 0.25rem !important;
  }

  .clndr-calendars-segment.clndr-floating {
    position: absolute !important;
    z-index: 2000;
  }

  .ui.segment,
  .ui.segments .segment {
    font-size: 1rem;
  }

  .ui.segment {
    position: relative;
    background: #fff;
    margin: 1rem 0;
    padding: 1em;
    border-radius: 0.28571429rem;
    border: 1px solid rgba(34, 36, 38, 0.15);
  }

  .clndr-top {
    bottom: 100%;
  }

  .clndr-left {
    left: 0;
  }

  .clndr-calendars-segment {
    text-align: center;
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }

  .clndr-control {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 10px;
    align-items: center;
  }

  i.icon.close:before {
    content: "\f00d";
  }

  i.icon.calendar.alternate.outline:before {
    content: "\f073";
  }

  i.icon.angle.double.right:before {
    content: "\f101";
  }
  i.icon {
    width: 18.875px;
  }

  i.icon:before {
    background: 0 0 !important;
  }

  i.icon.angle.right:before {
    content: "\f105";
  }

  i.icon.angle.right:before {
    content: "\f105";
  }

  i.icon {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome,sans-serif;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-weight: 1000;
  }

  .ui.basic.button,
  .ui.basic.buttons .button {
    background: transparent none !important;
    color: rgba(0, 0, 0, 0.6) !important;
    font-weight: 400;
    border-radius: 0.28571429rem;
    text-transform: none;
    text-shadow: none !important;
  }

  .ui.button {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: none;
    border: 1px solid #cccccc;
    vertical-align: baseline;
    background: #e0e1e2 none;
    color: rgba(0, 0, 0, 0.6);
    font-family: "Calibre", "Helvetica Neue", Arial, Helvetica, sans-serif;
    margin: 0 0.25em 0 0;
    padding: 0.45rem 0.5rem;
    text-transform: none;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.28571429rem;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
      box-shadow 0.1s ease, background 0.1s ease;
    will-change: "";
    -webkit-tap-highlight-color: transparent;
  }
  .ui.button:disabled {
    cursor: default;
    opacity: 0.45 !important;
    background-image: none !important;
    box-shadow: none !important;
    pointer-events: none !important;
  }

  i.angle.left.icon::before {
    content: "\f104";
  }

  i.angle.double.left.icon::before {
    content: "\f100";
  }

  .ui.input input {
    margin: 0;
    max-width: 100%;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.2142em;
    font-family: "Arial", "Helvetica Neue", Arial, Helvetica, sans-serif;
    padding: 0.43136154em 0.61538462em;
    background: #fff;
    border: 1px solid #ccc;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.2307em;
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
  }

  .ui.icon.input > i.icon {
    cursor: default;
    position: absolute;
    line-height: 1;
    text-align: center;
    top: 0;
    right: 0;
    margin: 0;
    height: 100%;
    width: 2.17692308em;
    opacity: 0.5;
    border-radius: 0 0.2307em 0.2307em 0;
    transition: opacity 0.3s ease;
  }

  .ui.icon.input input {
    padding-right: 2.17692308em !important;
  }

  .ui.icon.input > i.icon:before {
    left: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
    margin-top: -0.5em;
  }

  .ui.icon.input > i.icon:not(.link) {
    pointer-events: none;
  }
}
