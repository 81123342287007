@import "./../../../Theme/Components/All";

#Seller-Content-Container {
  background-color: $grey-000;
  float: left;
  clear: both;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  #Seller-Content {
    display: flex;
    justify-content: center;
    margin: 1rem;
    background-color: $white;
    border: 1px solid $grey-200;
    border-radius: 4px;
    padding: 1rem;
    font-size: 0.875rem;

    @include breakpoint(medium) {
      max-width: 650px;
      margin: 1.5rem auto;
      padding: 2.5rem;
    }
  }
}
