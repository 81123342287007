@import "../../../Theme/Components/All";

#Body-Content {
  background-color: $grey-000;
}

.content-container {
  flex-direction: row;
  display: flex;
  height: 100%;
}
