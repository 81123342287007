@import "./../../../../Theme/Components/All";

.product-details-attachment-container {
    padding-bottom: 1rem;
}

.product-attachment {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $theme-primary;
    border-top: 2px solid $grey-300;
    border-bottom: 2px solid $grey-300;
    padding: 0.5rem 0;

    .attachment-label {
        display: flex;
        padding-right: 0.5rem;

        i.icon {
            font: normal normal normal 1.2rem FontAwesome, sans-serif;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
        }
    }

    #attachment-description {
        font-weight: 600;
        color: #00326B;
    }
}