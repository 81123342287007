@import "./../../../../Theme/Components/All";

.product-details-price {
  font-size: 1.2rem;
  color: $grey-700;
  min-height: 1.25rem;

  .special-price {
    color: #f52a2a;

    .original-price {
      text-decoration: line-through;
      padding-left: 0.5rem;
      color: #4f4f4f;
    }
  }
}
