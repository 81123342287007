@import "./../../../../Theme/Components/All";

.promotion-tile {
  background-color: $c13;
  color: $c00;
  position: absolute;
  top: 20.5px;
  left: -32px;
  transform: rotate(315deg);
  padding: 0.4rem 3rem;
  clip-path: polygon(24% 0%, 76% 0%, 100% 100%, 0% 100%);
  cursor: pointer;
  text-transform: uppercase;
  z-index: 10;
  font-size: 0.875rem;

  &.list-view {
    padding: 0.1rem 0.5rem;
    left: unset;
    top: 9px;
    right: -21px;
    transform: translateX(-50%);
    clip-path: unset;
    border-radius: 0.5rem;
  }
}
