#Side-Image-Theme {
  float: left;
  clear: both;
  width: 100%;
  max-width: 100%;
  position: relative;
  padding: 0 4.8rem;
  height: 100%;

  @include breakpoint(medium) {
    padding: 0;
  }

  .side-image-theme-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    flex-wrap: wrap;

    @include breakpoint(medium) {
      align-content: center;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    @include breakpoint(large) {
      flex-direction: row;
    }

    .side-image-theme-content {
      float: left;
      clear: both;
      width: 100%;
      @include global-order(1);
      margin: auto 0;
      padding: 0.9375rem 0;
      text-align: center;
      align-self: center;

      @include breakpoint(medium) {
        max-width: 330px;
        padding: 0.9375rem 1.25rem;
        margin: auto;
      }

      @include breakpoint(large) {
        @include global-order(2);
      }

      .side-image-theme-header {
        float: left;
        clear: both;
        width: 100%;
        margin: 0 0 0.625rem;

        .cin7-heading-icon-container {
          float: left;
          clear: both;
          width: 100%;
          margin: 0 0 1.125rem;
        }

        h2 {
          width: 100%;
          float: left;
          clear: both;
          font-size: 1.25rem;
          font-weight: normal;
          line-height: 1.2;
          margin: 0 0 1.5625rem;
          color: $cin7-default-font-color;

          &.icon-on-top {
            margin: 0 0 0.625rem;
          }

          &.page-title {
            font-size: 1.5rem;
            line-height: 1;
          }

          &.failed-message {
            color: $cin7-failed-message-text-color;
            font-size: 1.5rem;
            font-family: $calibre-font-face;
            line-height: 1;
            text-align: center;
            font-weight: 700;
            margin: 0 0 1.875rem;
          }

          strong {
            font-weight: 700;
          }
        }

        h3 {
          width: 100%;
          float: left;
          clear: both;
          font-size: 1.125rem;
          line-height: 1.22;
          margin: 0;

          &.failed-subtitle {
            color: $cin7-form-section-legend-color;
            font-size: 1.125rem;
            line-height: 1.22;
          }

          .legend {
            float: left;
            clear: both;
            width: 100%;
            margin: 0 0 0.5rem;

            &.light {
              font-size: 1rem;
              line-height: 1.38;
              color: $cin7-form-section-legend-color;
            }
          }

          .email-text {
            float: left;
            clear: both;
            width: 100%;

            strong {
              font-size: 1rem;
              line-height: 1.38;
              font-weight: 700;
            }
          }
        }

        .company-logo-container {
          position: relative;
          max-width: 225px;
          width: 100%;
          height: 150px;
          display: flex;
          justify-content: center;
          margin: auto auto 20px auto;
          align-items: flex-end;
          font-size: 2.1rem;
          line-height: 1.2;
          font-weight: 500;
          color: $navy;

          .image-container {
            align-items: flex-end;

            img {
              margin-bottom: 0;
            }
          }
        }
      }

      .side-image-theme-body {
        float: left;
        clear: both;
        width: 100%;

        .side-image-theme-body-icon {
          margin: 3.5rem auto 0;
        }

        .side-image-theme-text-content {
          padding: 0 0.875rem;
          font-size: 1rem;
          line-height: 1.38;
          text-align: center;
          color: $cin7-form-section-legend-color;

          .email-container {
            display: flex;
            justify-content: center;
            padding-top: 0.625rem;
          }

          .emphasis {
            color: $cin7-default-font-color;
            font-weight: 700;
          }
        }

        .confirmation-box-content {
          margin: 0 0 1.25rem;
        }
      }
    }
  }
}
