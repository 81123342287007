@import "./../../../Theme/Components/All";

.header-search-container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  color: $theme-primary;

  &:hover {
    cursor: pointer;
  }

  .search-label {
    display: none;
    padding-left: 0.5rem;

    @include breakpoint(medium) {
      display: block;
    }
  }
}
