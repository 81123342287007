@import "./../../../Theme/Components/All";

.cart-page {
    background-color: $grey-000;
    float: left;
    clear: both;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    max-width: 1280px;
    margin: auto;

    @include breakpoint(large) {
        display: flex;
        justify-content: space-between;
    }

    &__header {
        display: flex;
        margin: 1rem 1rem 0 1rem;
        justify-content: space-between;
    }

    &__cart-inner-content {
        width: 100%;

        @include breakpoint(large) {
            overflow-y: auto;

            &::-webkit-scrollbar-thumb {
                background-color: $grey-400;
                border: 3px solid transparent;
                border-radius: 0.625rem;
                background-clip: content-box;
            }
        
            &::-webkit-scrollbar-track {
                background-color: $grey-000;
            }
        
            &::-webkit-scrollbar {
                width: 12px;
                height: 12px;
            }
        }

        .order-review-content {
            max-width: 1280px;
            margin: auto;
        
            @include breakpoint(large) {
                display: flex;
            }
        
            .checkout-footer-desktop {
                display: none;
        
                @include breakpoint(large) {
                    display: block;
                }
            }
        }
    }

    &__clear-cart-container {
        display: flex;
        align-items: center;

        .clear-cart-button {
            font-size: 1rem !important;
            font-weight: 500;
        }
    }

    &__checkout-footer-desktop {
        display: none;

        @include breakpoint(large) {
            display: initial;
        }
    }

    &__checkout-totals-desktop {
        display: none;

        @include breakpoint(large) {
            margin-top: 2rem;
            display: initial;
            right: 0;
        }

        @include breakpoint(large) {
            margin: 2rem;
            min-width: 320px;
        }
    }

    &__checkout-totals-mobile {

        @include breakpoint(large) {
            display: none;
        }
    }

    &__issues-container {
        padding: 1rem;
        padding-bottom: 0;
    
        .close-button {
            width: 100%;

            @include breakpoint(large) {
                width: auto;
                min-width: 120px;
            }
        }
    }

    &__empty-cart-container {
        width: 100%;
        padding: 2rem 1rem;
        display: flex;
    }
}

.checkout-footer-mobile {

  @include breakpoint(large) {
    display: none;
  }
}
