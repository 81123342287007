.chip {
  display: inline-flex;
  align-items: center;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;

  &:not(:last-child) {
    margin-right: 0.25rem;
  }

  &__label {
    font-size: 0.75rem;
    line-height: 1;
  }

  &__delete-icon {
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    svg.primary {
      display: block;

      path,
      circle {
        stroke: #fff;
      }
    }
  }
}
