@import "./../../../Theme/Components/All";

#Search-Modal {
  width: 100%;

  .modal-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $background-overlay-modal-color;
    z-index: 100;
  }

  .search-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    margin-top: 62px;
    z-index: 1000;
  }

  .results {
    border-top: 1px solid rgb(199, 199, 199);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: white;
    max-width: 800px;
    width: 100%;
    padding: 1rem;
    .result {
      padding: 0.3rem;
      &:not(:last-child) {
        border-bottom: 1px solid rgb(238, 238, 238);
      }

      &:hover {
        cursor: pointer;
        background-color: rgb(235, 235, 235);
      }

      .result-sku {
        color: $grey-500;
        font-size: 0.875rem;
      }
    }

    .view-more-button {
      margin-top: 1rem;
    }
  }

  .search-container {
    position: absolute;
    width: 100%;
    background: $white;
    z-index: 200;

    .search-input-container {
      display: flex;
      align-items: center;
      background-color: $white;
      padding: 0.9375rem;
      width: 100%;
      height: 62px;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;

      svg {
        max-width: 25px;
      }

      .clear-icon,
      .search-button,
      .cancel-button {
        &:hover {
          cursor: pointer;
        }
      }

      .search-label {
        display: flex;
        width: 100%;
        font-size: 1.625rem;
        padding: 0 0.9375rem;
        color: $grey-500;

        input {
          width: 100%;
          border: none;
          font-size: 1.25rem;

          &:focus {
            outline: none;
          }
        }
      }

      .cancel-button {
        font-size: 0.75rem;
        color: $black;
        padding-left: 0.9375rem;
      }
    }

    .search-results-container {
      background-color: $grey-000;
      padding: 0 0.9375rem;
      width: 100%;
      height: 100%;
    }

    .search-more-container {
      display: flex;
      width: 100%;
      height: 80px;
      justify-content: flex-end;
      align-items: center;
      align-self: flex-end;
      background: $white;
      border: 1px solid $grey-400;

      .more-results {
        font-size: 1.625rem;
        padding: 0 1.875rem;
        cursor: pointer;
        color: $grey-700;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .more-results-label {
          padding: 0 1.25rem;

          @include breakpoint(small) {
            padding: 0 2.5rem;
          }
        }
      }
    }
  }
}
