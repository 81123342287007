@import "./../../../../Theme/Components/All";

.homepage-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        max-width: 220px;
        max-height: 220px;
    }
}