@import "../../../../Theme/Components/All";

#Order-Review-Details-List {

  .product-tile {

    .product-tile-inner {
      margin: 1rem;
      background-color: $white;
      border: 2px solid #DCDCDC;
      box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
      border-radius: 6px;

      .product-tile-inner-details-container {
        display: flex;
        padding: 1rem;

        @include breakpoint(medium) {
          margin: 0 1rem;
          padding: 1rem 0;
        }

        .product-image-container {
          width: 65px;
          position: relative;
          margin-right: 7px;
          cursor: pointer;

          svg {
            max-width: 45px;
          }

          @include breakpoint(medium) {
            width: 80px;
            height: 80px;

            svg {
              max-width: 75px;
            }
          }
        }

        .product-detail-container {
          flex-grow: 2;

          .product-title {
            font-size: 1.25rem;
            color: #00326B;
            line-height: 1.25;
            word-wrap: break-word;
            cursor: pointer;
          }

          .product-code {
            display: block;
            font-size: 1rem;
            color: #777777;
          }
        }

        .product-action-container {
          .product-cart-delete {
            cursor: pointer;

            svg {
              width: 40px;
              height: 40px;
            }
          }
        }
      }

      .product-options {

        .product-options-section {
          display: flex;
        }

        .product-total-container {
          display: grid;
          grid-template-columns: auto auto;
          grid-template-areas: "Items Total";
          padding: 1rem;
          border-top: 2px solid #DCDCDC;

          @include breakpoint(medium) {
            grid-template-columns: auto auto 30px;
            grid-template-areas:
              "Items Total .";
          }

          .product-total-items {
            grid-area: Items;
            font-size: 1.125rem;
            color: $black;
            font-weight: 500;
          }

          .product-total-price {
            grid-area: Total;
            font-size: 1.125rem;
            text-align: right;
            color: $black;
            font-weight: 500;
          }
        }
      }
    }
  }
}
