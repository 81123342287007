@import "../../../../Theme/Components/All";

#Payment-Panel {
  color: $black;
  margin-top: 1.5rem;

  .payment-title {
    font-size: 1.125em;
    font-weight: bold;
    padding-bottom: 1rem;
  }

  .no-payment-methods-content {
    padding: 1rem;
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    font-size: 0.875rem;
  }

  .payment-inner-content {
    padding: 1rem;
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    display: grid;
    align-items: center;
    grid-template-columns: 70px auto;

    .payment-option {

      .payment-label {
        color: $theme-primary;
        font-weight: 600;
      }
    }
  }
}
