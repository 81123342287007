@import "./../../../../Theme/Components/All";

#Products-List-Items {
  .applied-filters {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }

  .clear-button {
    cursor: pointer;
    white-space: nowrap;
  }

  .products-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: $white;
    border-bottom: 1px solid $grey-200;

    .header-filters-action {
      flex: 2 2 0.625rem;

      .filter-icon-container {
        padding: 0.625rem 0.625rem 0.625rem 0;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .chips {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: -0.5rem;

      .chip {
        white-space: nowrap;
        margin-bottom: 0.25rem;

        &.show-more {
          cursor: pointer;
        }
      }

      :not(:last-child) {
        margin-right: 0.25rem;
      }
    }

    .header-search-results {
      font-size: 0.875rem;
      flex: 2 2 1.25rem;
      text-align: center;
      margin-bottom: 0.25rem;

      span.search-results-parameter {
        font-weight: bold;
        margin-right: 0.25rem;
      }

      .clear-search-icon {
        height: 1rem;
        width: 1rem;
        display: inline-block;
        cursor: pointer;
      }
    }
  }

  .products-list-filter-container {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
      height: auto;
    }

    .products-loading-container {
      width: 100%;
      padding: 3.75rem 0;

      @include breakpoint(medium) {
        height: auto;
      }
    }

    .infinite-scroll-component__outerdiv {
      width: 100%;
    }
  }

  .products-list-body {
    display: grid;
    overflow: hidden;
    grid-template-columns: 1fr;
    width: 100%;

    @include breakpoint(medium) {
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(1, 1fr);
    }

    @media only screen and (min-width: 1400px) {
      grid-template-columns: repeat(1, 1fr);
      &.list-view {
        max-width: 1440px;
        margin: 0 auto;
      }
    }

    &.gallery-view {
      @include breakpoint(small) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include breakpoint(medium) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include breakpoint(large) {
        grid-template-columns: repeat(4, 1fr);
      }

      @include breakpoint(extraLarge) {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }

  .load-more-button {
    text-align: center;
    padding: 0.625rem 1.25rem;

    .btn {
      width: 200px;
    }
  }
}

.no-results-panel {
  text-align: center;
  margin: 2.5rem auto;
  font-size: 1rem;
  width: 100%;
}
