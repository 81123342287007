@import "./../../../Theme/Components/All";

.default-button {
    display: inline-block;
    padding: 0px 17px;
    font-weight: 500;
    font-size: 1rem;
    border-radius: 8px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    white-space: nowrap;
    box-sizing: border-box;
    transition: all 0.1s;
    text-rendering: optimizeLegibility;
    letter-spacing: 0.1px;
    line-height: 1.1;
    height: 40px;

    @include breakpoint(medium) {
        min-width: 100px;
    }

    &.filled {
        color: white;
        background: $theme-primary;
        border: none;
        &:hover {
            background-color: $cin7-blue;
            border-color: $cin7-blue;
            color: white;
        }
    }
    &.outline {
        color: $theme-primary;
        background: rgba(0, 0, 0, 0);
        border: 2px solid $theme-primary;
        &:hover {
            background-color: $cin7-blue;
            border-color: $cin7-blue;
            color: white;
            i.icon {
                color: white;
            }
        }
    }
    &.fullWidth{
        width: 100%;
    }

}
