@import "../../../../Theme/Components/All";

.order-history-list {
  display: flex;
  flex-direction: column;

  .input-icon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .order-search-box {
    min-width: 190px;
  }

  .search-input-icon {
    margin-right: 0.25rem;
  }

  .order-history-search {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 0.625rem;
    color: $grey-700;

    .order-search-label {
      margin: 0 8px;
    }

    @include breakpoint(small) {
      flex-direction: row;
      align-items: center;
    }

    .ui.input.cin7-input-left.search > .cin7-input-left-field {
      @include breakpoint(minimumRes) {
        width: 100%;
      }

      & > .cin7-clickablewrapper {
        width: auto;
        padding: 5px;
        display: flex;
      }
    }
  }

  .order-history-list-results {
    overflow-x: auto;

    .order-headers {
      white-space: nowrap;

      .order-status {
            text-align: center;
      }

      .order-total-quantity,
      .order-total,
      .order-outstanding-amt {
        text-align: right;
      }

      .order-action {
        text-align: center;
      }
    }
  }

  .cin7-segment-content:hover:after {
    border-top: none;
    border-bottom: none;
  }

  .order-history-help-text {
    padding-top: 1.25rem;
    margin: 0;
    color: $grey-500;
  }

  .reorder-modal-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
}
