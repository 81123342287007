@import "./../../../../Theme/Components/All";

.product-details-gallery {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include breakpoint(medium) {
    padding-bottom: 1rem;
  }

  #Product-Gallery-Main {
    margin: 0 auto;
    display: flex;
    align-items: center;

    #Main-Image-View {
      position: relative;
      display: flex;
      justify-content: center;
      width: 250px;
      height: 250px;
      cursor: zoom-in;

      @include breakpoint(minimumRes) {
        width: 120px;
        height: 120px;
      }

      @include breakpoint(medium) {
        width: 250px;
        height: 250px;
        margin-bottom: 1rem;
      }

      @include breakpoint(large) {
        width: 300px;
        height: 300px;
        margin-bottom: 1rem;
      }

      @include breakpoint(extraLarge) {
        width: 400px;
        height: 400px;
        margin-bottom: 1rem;
      }
    }

    .next-image,
    .prev-image {
      width: 50px;
      position: absolute;
    }

    .next-image {
      right: 0;
    }

    .prev-image {
      left: 0;
      z-index: 2;
    }
  }

  #Product-Gallery-Options-Mobile {
    display: none;
  }

  #Product-Gallery-Options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1.125rem 0 0 0;
    @include breakpoint(medium) {
      justify-content: space-between;
    }

    &.left-align {
      justify-content: left;

      .product-options {
        &:last-child {
          margin: 0 0 0 0.625rem;
          @include breakpoint(medium) {
            margin: 0 0 0 1.25rem;
            justify-content: space-between;
          }
        }
      }
    }

    @include breakpoint(medium) {
      margin: 0;
    }

    .product-options {
      position: relative;
      width: 75px;
      height: 75px;
      border: 2px solid;
      cursor: pointer;

      @include breakpoint(large) {
        width: 100px;
        height: 100px;
      }

      &.active {
        border-color: $cin7-gallery-image-option-active-border;
      }

      &.inactive {
        border-color: $transparent;
      }
    }
  }
}
