//Base Colours
$cin7-blue: #002dff;
$theme-primary: #00326b;
//1. General Colours
$c00: #ffffff;
$c01: #000000;
$c02: #6d6e74;
$c03: #f1f3f4;
$c04: #595d60;
$c05: #cccccc;
$c06: #33788e;
$c07: #0c2b3a;
$c08: #cc3333;
$c09: #5d9e57;
$c10: #88cde2;
$c11: #ef5250;
$c12: #ffb100;
$c13: #dd3b3b;
$c14: #edf5f7;
$c15: #ebf5ea;
$c16: #fff2d5;
$c17: #f9e2d9;
$c19: #dfdfdf;
$c20: #004fa9;
$c21: #00326b;
$c22: #008000;
$c23: #727274;
$c24: #9e9ea0;
$c25: #cecece;
$c26: #e7f3fd;
$c27: #393939;
$c28: #4e4f4f;
$c29: #808081;
$c30: #b1b1b2;
$c31: #e3e4e5;
$c32: #ececee;
$c33: #f2f2f4;
$c34: #f8f9fb;
$c35: #383939;
$c36: #212121;
$c37: #d2d2d2;
$c38: #333333;

$space-light: #778c94;
$space-dark: #707070;
$grey: #a7a7a7;
$navy: $c07;
$white: $c00;
$black: $c01;

$grey-900: $c36;
$grey-800: $c35;
$grey-700: $c28;
$grey-600: $c29;
$grey-500: $c30;
$grey-400: $c19;
$grey-300: $c31;
$grey-200: $c32;
$grey-100: $c33;
$grey-000: $c34;

//Custom Transparent Colors
$transparent: rgba(0, 0, 0, 0);
$background-overlay-color: rgba(0, 0, 0, 0.4);
$background-overlay-modal-color: rgba(0, 0, 0, 0.8);

//Shadows
$cin7-blue-shadow: 0 0 0 0.125rem rgba(0, 45, 255, 0.9);
$cin7-invalid-input-shadow: 0 0 0 0.625rem rgba(204, 51, 51, 0.9);
$primary-container-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 2px 1px 4px rgba(0, 0, 0, 0.04);

//B2B Colours
$cin7-placeholder-font-color: $c02;
$cin7-input-field-background: $c03;
$cin7-input-field-border-color: $c05;
$cin7-input-field-font-color: $c07;
$cin7-label-font-color: $c04;
$cin7-float-label-font-color: $c02;
$cin7-active-float-label-font-color: $c06;
$cin7-link-text-color: $c06;

//B2B Text
$cin7-default-font-color: $c07;
$cin7-form-section-legend-color: $c02;
$cin7-failed-message-text-color: $c13;
$cin7-sku-blue-label: $c20;
$cin7-product-stock-label: $c22;
$cin7-b2b-brand-label: $c23;
$cin7-b2b-price-range-label: $c24;
$cin7-b2b-items-label: $c21;
$cin7-b2b-product-teaser-text: $c23;
$cin7-b2b-discount-label: $c09;

//B2B Mini Cart
$cin7-mini-cart-currency-color: $c38;
$cin7-mini-cart-order-background: $c26;

//Cin7 Buttons
$cin7-button-background-color: $c07;
$cin7-button-border-color: $cin7-button-background-color;
$cin7-button-focus-font-color: $c10;
$cin7-button-active-background-color: $c02;
$cin7-button-active-border-color: $cin7-button-active-background-color;
$cin7-goback-label: $c27;

//Validation Colours
$cin7-invalid-color: $c08;
$cin7-input-valid-border-color: $c07;

//SVG ICON Colours
$cin7-clear-input-icon-color: $c02;
$cin7-eye-icon-color: $c02;
$cin7-valid-input-icon-color: $c09;

//Cin7 Password Meter
$cin7-progress-default-background: $c03;
$cin7-progress-weak-background: $c11;
$cin7-progress-weak-font-color: $cin7-progress-weak-background;
$cin7-progress-fair-background: $c12;
$cin7-progress-fair-font-color: $cin7-progress-fair-background;
$cin7-progress-great-background: $c09;
$cin7-progress-great-font-color: $cin7-progress-great-background;

//Cin7 Confirmation Box
$cin7-confirmation-box-default-background: $c14;
$cin7-confirmation-box-success-background: $c15;
$cin7-confirmation-box-warning-background: $c16;
$cin7-confirmation-box-error-background: $c17;
$cin7-confirmation-box-default-font: $c02;

//Cin7 Product Image Gallery
$cin7-gallery-image-option-active-border: $c21;

//Cin7 Checkout Progress Breadcrumb
$cin7-progress-breadcrumb-completed: $c22;
$cin7-progress-breadcrumb-current: $c07;
$cin7-progress-breadcrumb-pending: $c25;
