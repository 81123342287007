@import '../../../../Theme/Components/All';

.order-history-list {

    .order-tile {
        white-space: nowrap;
        font-weight: 500;

        .order-reference a {
            color: $cin7-sku-blue-label;
            text-decoration: none;
        }

        .order-status {
            text-align: center;
        }

        .order-status-open {
            border: 1px solid rgba(254, 219, 49, 0.25);
            background: rgba(254, 219, 49, 0.25);
            color: #000;
            font-size: 14px;
            line-height: 1;
            text-decoration: none;
            height: 24px;
            width: 91px;
            text-align: center;
            padding: 4px 0;
            margin: 10px auto;
            border-radius: 120px;
            display: block;
        }

        .order-status-dispatched {
            border: 1px solid rgba(77, 152, 255, 0.25);
            background: rgba(77, 152, 255, 0.25);
            color: #000;
            font-size: 14px;
            line-height: 1;
            text-decoration: none;
            height: 24px;
            width: 91px;
            text-align: center;
            padding: 4px 0;
            margin: 10px auto;
            border-radius: 120px;
            display: block;
        }

        .order-total-quantity, .order-total, .order-outstanding-amt {
            text-align: right;
        }

        .order-outstanding-amt {
            color: red;
        }

        .order-action {
            color: $cin7-sku-blue-label;
            text-align: center;

            &:hover {
                cursor: pointer;
            }
        }
    }
}