@import "./../../../Theme/Components/All";

.loading-container {
  float: left;
  clear: both;
  width: 100%;
  position: relative;
  padding: 1.25rem 0;
  color: #333;
  text-align: center;

  &.full-screen {
    top: 50%;
    transform: translateY(-50%);
  }

  &.take-over {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(1, 1, 1, 0.2);
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .full-screen-background {
      background-color: $white;
      border-radius: 4px;
      padding: 2rem 3rem;
      max-width: 600px;
    }
  }

  .loading-dots {
    display: flex;
    justify-content: center;

    div {
      width: 1.25rem;
      height: 1.25rem;
      margin: 1.25rem 0.75rem;
      background: #333;
      border-radius: 50%;
      animation: 0.9s loading-bounce infinite alternate;

      &:nth-child(2) {
        animation-delay: 0.3s;
      }

      &:nth-child(3) {
        animation-delay: 0.6s;
      }
    }

    @keyframes loading-bounce {
      to {
        opacity: 0.3;
        transform: translate3d(0, -0.625rem, 0);
      }
    }
  }

  .loading-label {
    float: left;
    clear: both;
    width: 100%;
    color: #333;
    font-size: 2.125rem;
    line-height: 0.73;
    text-align: center;
    font-weight: 500;
    margin: 0 0 1.5625rem;
  }
}
