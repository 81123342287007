@import "./../../../Theme/Components/All";

#Sidebar {
  width: 100%;
  font-size: 1.25rem;
  height: calc(100% - 80px);
  background-color: #f8f9fb;
  position: absolute;
  left: -100%;
  top: 80px;
  z-index: 111;
  overflow-x: hidden;
  @include global-transition($global-side-nav-transition);

  @include breakpoint(medium) {
    top: 0;
    height: 100%;
    max-width: 350px;
    background-color: white;
    font-size: 1rem;
  }
  &.active {
    left: 0;
    z-index: 150;
    @include breakpoint(medium) {
      position: fixed;
      z-index: 300;
    }
  }

  .nav-content {
    display: block;
    min-height: calc(100vh - 80px);

    @media only screen and (max-height: 700px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .account-sticky-container {
      bottom: 0;
      position: fixed;
      width: 100%;
      background-color: $white;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
      @media only screen and (max-height: 700px) {
        position: relative;
      }

      .account-content {
        width: 100%;

        a {
          color: $theme-primary;
          font-weight: 500;
        }

        .profile-info {
          margin: 0.5rem 0 0;
          flex-direction: row;
          display: flex;
          justify-content: space-between;
          font-size: 1.25rem;
          padding: 0.5rem 1rem;
          cursor: pointer;
          font-weight: 500;
          border-bottom: 0.5px #ddd solid;

          .profile-company {
            font-weight: bold;
          }
        }

        .account-links {
          font-size: 1.25rem;
          margin-bottom: 0.5rem;

          .account-link {
            padding: 0.5rem 1rem;
            cursor: pointer;
            display: flex;
            color: $theme-primary;
            font-weight: 500;
            border-bottom: 0.5px #ddd solid;

            a {
              display: flex;
              align-items: center;
              svg {
                vertical-align: sub;
                margin-right: 0.5rem;
                height: 24px;
                width: 24px;
              }
            }
          }
        }

        .logout-button {
          width: 100%;
          margin: 0;
        }
      }

      .nav-footer-content {
        width: 100%;
        align-self: flex-end;

        .footer-item {
          padding: 0 1rem;
          font-size: 0.9375rem;

          &:hover {
            cursor: pointer;
          }

          a {
            color: $theme-primary;
          }
        }
      }
    }
    .terms-container {
      text-align: center;
      margin-top: 0.625rem;

      a {
        color: $cin7-blue;
        font-size: 0.75rem;
        padding-top: 0;
      }
    }
  }
  @include breakpoint(medium) {
    .nav-content {
      display: flex;
      width: 100%;
      height: 100%;
      flex-wrap: wrap;
      padding-bottom: 0;

      .account-content {
        color: $grey-800;
        width: 100%;
        margin: 1.25rem 0 0;
        padding: 0 1.4375rem;

        a {
          color: $grey-800;
        }

        .profile-info {
          margin: 0.9375rem 0 0;

          .profile-name {
            font-size: 1.625rem;
            padding-bottom: 0.4375rem;
            line-height: 1.2;
          }

          .profile-company {
            font-size: 1rem;
            font-weight: bold;
          }
        }

        .account-links {
          font-size: 1.25rem;
          margin: 1.5rem 0 3.8rem;

          .account-link {
            padding: 0.5rem 0;
            cursor: pointer;
            display: flex;

            a {
              display: flex;
              align-items: center;
              svg {
                vertical-align: sub;
                margin-right: 0.5rem;
                height: 24px;
                width: 24px;
              }
            }

          .upload-icon {
            height: 24px;
            width: 24px;
            margin-right: 0.5rem;

              svg.upload-simple-icon path {
                stroke: black;
              }
            }

            .label {
              padding-left: 0.5rem;
            }
          }
        }

        .logout-button {
          width: 100%;
          margin: 1.25rem 0 1.5rem;
        }
      }

      .nav-footer-content {
        width: 100%;
        background-color: $grey-000;
        border: 1px solid $grey-200;
        align-self: flex-end;
        padding: 1.25rem;

        .footer-item {
          padding: 0.625rem 0.3125rem;
          font-size: 1rem;

          @include breakpoint(medium) {
            font-size: 0.9375rem;
          }

          &:hover {
            cursor: pointer;
          }

          a {
            color: $grey-800;
          }
        }

        .terms-container {
          text-align: center;
          padding-top: 0.75rem;
          border-top: 1px solid $grey-400;
          margin-top: 0.625rem;

          a {
            color: $cin7-blue;
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

.shadow-bg {
  @include global-transition($global-side-nav-transition);
  position: absolute;
  z-index: 250;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: $black, $alpha: 0.6);
  top: 80px;
  @include breakpoint(medium) {
    top: 0px;
  }
}

.plain-div {
  position: inherit;
  bottom: inherit;
  width: inherit;
  display: contents;
}

.account-sticky-container {
  position: relative;
  width: 100%;
  bottom: auto;
}
