@import "./../../../Theme/Components/All";

#Contact-Form {
  float: left;
  clear: both;
  width: 100%;
  max-width: 100%;
  position: relative;
  padding: 44px;
  height: 100%;

  @include breakpoint(medium) {
    padding: 0;
  }

  .contact-form-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    flex-wrap: wrap;

    @include breakpoint(medium) {
      align-content: center;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    @include breakpoint(large) {
      flex-direction: row;
    }

    .contact-us-section {
      float: left;
      clear: both;
      width: 100%;
      @include global-order(1);
      margin: auto 0;
      padding: 0.9375rem 0;

      @include breakpoint(medium) {
        max-width: 800px;
        padding: 0.9375rem 1.25rem;
        margin: auto;
      }

      @include breakpoint(large) {
        @include global-order(2);
      }

      .contact-us-body {
        float: left;
        clear: both;
        width: 100%;

        .header {
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          padding-bottom: 7px;
        
          @include breakpoint(small) {
            font-weight: 500;
            font-size: 32px;
            line-height: 32px;
            border-bottom: 2px solid #D3D3D3;
            padding-bottom: 12px;
          }
        }

        .panel-content-group {
          float: left;
          clear: both;
          width: 100%;

          .panel-group-label {
            margin-bottom: 0.25rem;
            font-weight: 600;
          }

          .panel-group-label-error {
            margin-bottom: 0.25rem;
            font-weight: 600;
            color: #AA0000;
          }

          .panel-group-field {
            float: left;
            clear: both;
            width: 100%;
          }

          .panel-group-error-text {
            font-weight: 500;
            line-height: 24px;
            font-size: 14px;
            color: #AA0000;
          }
        }

        textarea {
          width: 100%;
          border: 1px solid $c37;
          @include border-radius(4px);
          min-height: 7.125rem;
          max-height: 9.375rem;
          outline: none;
          font-weight: 500;
          line-height: 1.1876em;
          font-size: 14px;
          color: #383939;
          cursor: text;
          font-family: "Calibre", sans-serif;
          padding: 0.5rem;
        }

        textarea::placeholder {
          color: #BDBEBE;
          font-weight: 400;
        }

        textarea:focus {
          border: 2px solid #002EFF;
        }

        .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
          border-color: #AA0000;
        }

        .MuiOutlinedInput-notchedOutline {
          border-radius: 4px;
        }

        & .MuiOutlinedInput-root {
          &.Mui-focused fieldset {
            border-color: #002EFF;
          }
        }
      }
    }
  }
}

.return-to-login {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.email-failed-error {
  border: 2px solid #AA0000;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #AA0000;
}

.textAreaWithError {
  border: 1px solid #AA0000;
}

.store-name {
  float: left;
  clear: both;
  width: 100%;
  margin: 0 0 0.625rem;

  .cin7-heading-icon-container {
    float: left;
    clear: both;
    width: 100%;
    margin: 0 0 1.125rem;
  }

  h2 {
    width: 100%;
    float: left;
    clear: both;
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.2;
    margin: 0 0 1.5625rem;
    color: $cin7-default-font-color;

    &.icon-on-top {
      margin: 0 0 0.625rem;
    }

    &.page-title {
      font-size: 1.5rem;
      line-height: 1;
    }

    &.failed-message {
      color: $cin7-failed-message-text-color;
      font-size: 1.5rem;
      font-family: $calibre-font-face;
      line-height: 1;
      text-align: center;
      font-weight: 700;
      margin: 0 0 1.875rem;
    }

    strong {
      font-weight: 700;
    }
  }

  h3 {
    width: 100%;
    float: left;
    clear: both;
    font-size: 1.125rem;
    line-height: 1.22;
    margin: 0;

    &.failed-subtitle {
      color: $cin7-form-section-legend-color;
      font-size: 1.125rem;
      line-height: 1.22;
    }

    .legend {
      float: left;
      clear: both;
      width: 100%;
      margin: 0 0 0.5rem;

      &.light {
        font-size: 1rem;
        line-height: 1.38;
        color: $cin7-form-section-legend-color;
      }
    }

    .email-text {
      float: left;
      clear: both;
      width: 100%;

      strong {
        font-size: 1rem;
        line-height: 1.38;
        font-weight: 700;
      }
    }
  }

  .company-logo-container {
    position: relative;
    max-width: 225px;
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    margin: auto auto 20px auto;
    align-items: flex-end;
    font-size: 2.1rem;
    line-height: 1.2;
    font-weight: 500;
    color: $navy;

    .image-container {
      align-items: flex-end;

      img {
        margin-bottom: 0;
      }
    }
  }
}

.contact-us-grid {
  padding-top: 5px;

  .name {
    order: -3;
  
    @include breakpoint(small) {
      order: -3;
    }
  }
  
  .email-address {
    order: -2;
  
    @include breakpoint(small) {
      order: -1;
    }  
  }
  
  .phone-number {
    order: -1;
  
    @include breakpoint(small) {
      order: -2;
    }
  }
  
  .company-name {
    order: 0;
  }
  
  .message {
    order: 1;
  }
  
  .reCaptcha {
    order: 2;
  }
  
  .send-button {
    order: 3;
  
    @include breakpoint(small) {
      order: 4;
    }
  }
  
  .cancel-button {
    order: 4;
  
    @include breakpoint(small) {
      order: 3;
    }
  
  }
  
  .error-grid {
    order: 5;
  }
}
