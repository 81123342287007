@import "./../../../Theme/Components/All";

.mini-cart-tax {
  display: flex;
  visibility: visible;
  justify-content: center;
  height: 100%;

  .mini-cart-tax-type {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $cin7-mini-cart-currency-color;
    line-height: 1.625rem;

    .mini-cart-tax-currency {
      font-weight: 600;
    }
  }
}
