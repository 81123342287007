@import "./../../../Theme/Components/All";
.nav-menu {
  font-size: 1.25rem;
  overflow-y: auto;
  height: 100%;
  @include breakpoint(medium) {
    top: 0;
    min-width: 275px;
    max-width: 275px;
    height: 100%;
    border-right: 1px solid #dfdfdf;
    font-size: 1rem;
  }
  ul {
    padding: 0;

    hr {
      margin: 0;
    }

    li,
    a {
      color: $c21;
      font-weight: 500;
      //font-size: 1.25rem;

      &.back {
        justify-content: left;
        align-items: center;
        i {
          margin-right: 1rem;
        }
      }

      &.current {
        color: black;
        font-weight: 600;
      }

      &.title {
        color: black;
        font-weight: 600;
        font-size: 1.5rem;
      }
    }
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.5rem 0 1rem;
      list-style-type: none;
      min-height: 56px;
      overflow-wrap: anywhere;
      word-wrap: anywhere;
      &.menu-item {
        border-bottom: 0.5px $grey-200 solid;
        &.desktop-only {
          display: none;
          @include breakpoint(medium) {
            display: flex;
          }
        }
        &:hover {
          background-color: #e1eeff;
        }
        @include breakpoint(medium) {
          border-bottom: 0;
        }
        &.top-item {
          border-bottom: 0;
        }
      }
    }
    li:hover,
    a:hover {
      color: $cin7-blue;
      cursor: pointer;
    }
    li.menu-item {
      i.fa-chevron-right {
        padding-left: 0.5rem;
      }
    }
  }
}

// Mobile
.animate-menu-backwards-enter {
  min-width: 100%;
  max-width: 100%;
  position: absolute;
  transform: translateX(-100%);
}
.animate-menu-backwards-enter-active {
  min-width: 100%;
  max-width: 100%;
  transform: translateX(0%);
  position: absolute;
}
.animate-menu-backwards-exit {
  min-width: 100%;
  max-width: 100%;
  position: relative;
}
.animate-menu-backwards-exit-active {
  min-width: 100%;
  max-width: 100%;
  transform: translateX(100%);
  position: relative;
}
.animate-menu-forwards-enter {
  min-width: 100%;
  max-width: 100%;
  transform: translateX(100%);
  position: absolute;
}
.animate-menu-forwards-enter-active {
  min-width: 100%;
  max-width: 100%;
  transform: translateX(0%);
  position: absolute;
}
.animate-menu-forwards-exit {
  min-width: 100%;
  max-width: 100%;
  position: relative;
}
.animate-menu-forwards-exit-active {
  min-width: 100%;
  max-width: 100%;
  transform: translateX(-100%);
  position: relative;
}

// All
.animate-menu-backwards-enter-active {
  transition: transform 300ms;
  position: absolute;
}
.animate-menu-backwards-exit-active {
  transition: transform 300ms;
  position: relative;
}

.animate-menu-forwards-enter-active {
  transition: transform 300ms;
  position: absolute;
}
.animate-menu-forwards-exit-active {
  transition: transform 300ms;
  position: relative;
}

//Desktop
@include breakpoint(medium) {
  .animate-menu-backwards-enter {
    min-width: 275px;
    max-width: 275px;
    position: absolute;
    transform: translateX(-100%);
  }
  .animate-menu-backwards-enter-active {
    min-width: 275px;
    max-width: 275px;
    transform: translateX(0%);
  }
  .animate-menu-backwards-exit {
    position: absolute;
    min-width: 275px;
    max-width: 275px;
  }
  .animate-menu-backwards-exit-active {
    min-width: 275px;
    max-width: 275px;
    transform: translateX(100%);
  }
  .animate-menu-forwards-enter {
    min-width: 275px;
    max-width: 275px;
    position: absolute;
    transform: translateX(100%);
  }
  .animate-menu-forwards-enter-active {
    min-width: 275px;
    max-width: 275px;
    transform: translateX(0%);
  }
  .animate-menu-forwards-exit {
    position: absolute;
    min-width: 275px;
    max-width: 275px;
  }
  .animate-menu-forwards-exit-active {
    min-width: 275px;
    max-width: 275px;
    transform: translateX(-100%);
  }
}

.transition-group {
  overflow: hidden;
}

.navigation-menu-list {
  overflow: hidden;
}
