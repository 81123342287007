.cin7-form {
  float: left;
  width: 100%;
  clear: both;

  //Global Cin7 B2B form Styles
  .section-legend {
    float: left;
    clear: both;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    line-height: 1.38;
    color: $cin7-form-section-legend-color;
    margin: 0 0 0.3125rem;
  }

  .cin7-form-group {
    float: left;
    width: 100%;
    clear: both;
    margin: 0.625rem 0;
    position: relative;

    &.register-meter-margin {
      margin: 0.625rem 0 0.5rem;
    }

    input[type="text"],
    input[type="password"],
    input[type="email"] {
      &.cin7-input {
        font-family: $calibre-font-face;
        padding: 0.375rem 2.5rem 0.375rem 0.625rem;
        height: calc(2.45em + 0.46875rem);
        border: 1px solid $cin7-input-field-border-color;
        background-color: $cin7-input-field-background;
        outline: none;
        font-size: 1rem;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.21;
        text-align: left;
        color: $cin7-input-field-font-color;
        @include border-radius(5px);
        @include placeholder {
          color: $cin7-placeholder-font-color;
          text-transform: capitalize;
        }

        &:focus {
          outline: none;
          box-shadow: $cin7-blue-shadow;
        }

        /* Fix for autofill label float issue on Chrome */
        &:-webkit-autofill {
          padding-top: 0.75rem !important;
        }

        /* Fix for autofill label float issue on Chrome */
        &:-webkit-autofill ~ label {
          color: $cin7-active-float-label-font-color;
          @include global-transform(translate(0, 0) scale(0.75));
        }

        /* Fix for Firefox */
        &:not(:placeholder-shown) {
          padding-top: 0.75rem !important;
        }

        /* Fix for Firefox */
        &:not(:placeholder-shown) ~ label {
          color: $cin7-active-float-label-font-color;
          @include global-transform(translate(0, 0) scale(0.75));
        }
      }
    }

    label {
      font-size: 1rem;
      font-weight: normal;
      color: $cin7-label-font-color;
      line-height: 1.2;
      text-align: left;

      &.cin7-float-label {
        position: absolute;
        color: $cin7-float-label-font-color;
        font-size: 1rem;
        line-height: 1.21;
        top: 0.15625rem;
        left: 0.625rem;
        margin: 0;
        transform-origin: top left;
        @include global-transition($global-float-label-transition);
        @include global-transform(translate(0, 0.75rem) scale(1));
      }
    }

    button {
      outline: none;
      @include border-radius(0);
      padding: 0.75rem 1.625rem;
      font-family: $calibre-font-face;
      font-size: 0.875rem;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;

      &.cin7-custom-input-icon {
        visibility: hidden;
        z-index: 2;
        width: 20px;
        height: 20px;
        padding: 0.375rem 0.625rem;
        box-sizing: content-box;
        background: none;
        border: none;
        position: absolute;
        top: 50%;
        right: 0;
        @include global-transform(translateY(-50%));

        &:focus,
        &:active {
          background: none !important;
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }

        &:focus {
          box-shadow: $cin7-blue-shadow !important;
        }

        &.active {
          visibility: visible;

          &:not(:disabled):not(.disabled) {
            background: none !important;
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
          }
        }

        &.clear-icon {
          .cls-1 {
            stroke: $cin7-clear-input-icon-color;
          }
        }

        &.eye-icon {
          visibility: visible;

          .cls-1 {
            fill: none;
            stroke: $cin7-eye-icon-color;
          }
        }
      }

      &.cin7-button {
        float: left;
        width: 100%;
        padding: 0.875rem 1.625rem;
        background-color: $cin7-button-background-color;
        color: $white;
        border: 1px solid $cin7-button-border-color;
        outline: none;
        @include border-radius(4px);
        font-size: 1rem;
        line-height: 1.19;
        letter-spacing: 0.03125rem;
        font-weight: 400;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 1px 3px rgba(0, 0, 0, 0.02);

        &:hover,
        &:disabled {
          background-color: #1f4a5e;
          border-color: #1f4a5e;
        }

        &:focus {
          background-color: #1f4a5e;
          outline: none;
          box-shadow: 0 0 0 2px #ffffff, 0 0 0 3px #1f4a5e;
        }

        &:active,
        &:not(:disabled):not(.disabled):active {
          background-color: #346278;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
        }
      }
    }

    //When The Container is Active
    &.active {
      label {
        &.cin7-float-label {
          color: $cin7-active-float-label-font-color;
          @include global-transform(translate(0, 0) scale(0.75));
        }
      }

      input[type="text"],
      input[type="password"],
      input[type="email"] {
        &.cin7-input {
          padding: 1rem 2.5rem 0.375rem 0.625rem;
        }
      }
    }

    //Validation Styles
    &.validate-field-group {
      &.failed {
        margin-bottom: 0;
        label {
          &.cin7-float-label {
            color: $cin7-invalid-color;
          }
        }

        input[type="text"],
        input[type="password"],
        input[type="email"] {
          &.cin7-input {
            border: 1px solid $cin7-invalid-color;
          }
        }
      }

      &.passed {
        label {
          &.cin7-float-label {
            color: $cin7-float-label-font-color;
          }
        }

        input[type="text"],
        input[type="password"],
        input[type="email"] {
          &.cin7-input {
            border: 1px solid $cin7-input-valid-border-color;
          }
        }

        .valid-input-icon {
          position: absolute;
          padding: 0.375rem 0.625rem;
          top: 50%;
          right: 0;
          @include global-transform(translateY(-50%));

          .cls-1 {
            stroke: $cin7-valid-input-icon-color;
          }
        }
      }
    }

    &.button-ontainer {
      margin: 0.625rem 0;
    }
  }

  //Validation Message
  .validation-message {
    font-size: 0.75rem;
    line-height: 1.17;
    color: $cin7-invalid-color;
    text-align: left;
    float: left;
    clear: both;
    padding: 0.375rem 0;
    position: relative;
    width: 100%;
    display: none;

    &.show {
      display: block;
    }
  }

  //Terms and Conditions
  .cin7-form-tc {
    float: left;
    clear: both;
    width: 100%;
    margin: 0.9375rem 0;
    padding: 0 0.625rem;
    color: $cin7-default-font-color;
    font-size: 0.75rem;
    line-height: 1.125rem;

    a.tc-link {
      color: $cin7-blue;

      &.nav-link {
        display: inline-block;
        padding: 0;
      }

      &:hover {
        text-decoration: underline;
      }

      &:active,
      &:visited {
        color: $cin7-blue;
      }
    }
  }

  //Cin7 Password Meter
  .cin7-password-meter {
    float: left;
    clear: both;
    width: 100%;
    text-align: center;

    &.hide {
      display: none;
    }

    .cin7-password-meter-progress {
      float: left;
      clear: both;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      box-shadow: none;
      border: none;
      width: 100%;
      height: 6px;

      &::-webkit-progress-bar {
        background-color: $cin7-progress-default-background;
        @include border-radius(1px);
      }

      &::-moz-progress-bar {
        background-color: $cin7-progress-default-background;
        @include border-radius(1px);
      }

      &::-webkit-progress-value {
        @include border-radius(1px);
        background-size: 35px 20px, 100% 100%, 100% 100%;
      }

      //Weak
      &.strength-Weak::-webkit-progress-value {
        background-color: $cin7-progress-weak-background;
      }
      &.strength-Weak::-moz-progress-bar {
        background-color: $cin7-progress-weak-background;
      }
      &.strength-Weak::-ms-fill {
        background-color: $cin7-progress-weak-background;
      }

      //Fair
      &.strength-Fair::-webkit-progress-value {
        background-color: $cin7-progress-fair-background;
      }
      &.strength-Fair::-moz-progress-bar {
        background-color: $cin7-progress-fair-background;
      }
      &.strength-Fair::-ms-fill {
        background-color: $cin7-progress-fair-background;
      }

      //Great
      &.strength-Great::-webkit-progress-value {
        background-color: $cin7-progress-great-background;
      }
      &.strength-Great::-moz-progress-bar {
        background-color: $cin7-progress-great-background;
      }
      &.strength-Great::-ms-fill {
        background-color: $cin7-progress-great-background;
      }
    }

    .cin7-password-meter-label {
      float: left;
      clear: both;
      width: 100%;
      font-family: "Calibre", sans-serif;
      font-size: 0.75rem;
      line-height: 1.27;
      letter-spacing: 2px;
      margin: 0.3125rem 0 0;
      height: 0.875rem;

      &.strength-Weak {
        color: $cin7-progress-weak-font-color;
      }

      &.strength-Fair {
        color: $cin7-progress-fair-font-color;
      }

      &.strength-Great {
        color: $cin7-progress-great-font-color;
      }

      strong {
        font-weight: 700;
      }
    }
  }

  .form-check {
    float: left;
    width: 100%;
    clear: both;
    margin: 1.25rem 0 0;
    display: flex;

    label.form-check-label {
      align-self: center;
      margin: 0 auto;
      font-size: 0.875rem;
      color: $space-light;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: normal;

      .custom-checkbox {
        float: left;
        margin: 0 0.625rem 0 0;
        position: relative;
        display: block;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        height: 23px;
        width: 23px;

        input[type="checkbox"] {
          float: left;
          margin: 0.125rem 0 0 0;
          z-index: -9999;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;

          &:checked ~ .checkmark:after {
            display: block;
          }
        }

        .checkmark {
          position: absolute;
          top: -0.1875rem;
          left: 0;
          height: 23px;
          width: 23px;
          border: 1px solid $space-dark;
          @include border-radius(0);
          background-color: $white;

          &:after {
            content: "";
            position: absolute;
            display: none;
            left: 8px;
            top: 2px;
            width: 6px;
            height: 12px;
            border: solid $navy;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      .checkbox-text {
        float: left;
      }

      .checkbox-link {
        float: left;
        margin-left: 0.625rem;
      }
    }
  }
}

.login-pages-link {
  float: left;
  clear: both;
  width: 100%;
  text-align: center;
  margin: 1.625rem 0 0.625rem;
  font-size: 1rem;
  line-height: 1.19;
  color: $cin7-link-text-color;
  font-weight: 700;

  &:link,
  &:visited {
    color: $cin7-link-text-color;
    text-decoration: underline;
  }

  &:active,
  &:hover {
    color: $cin7-link-text-color;
    text-decoration: none;
  }
}

.confirmation-content {
  float: left;
  width: 100%;
  clear: both;
}

.cin7-black-button {
  float: left;
  width: 100%;
  clear: both;
  @include border-radius(2px);
  background-color: $black;
  font-size: 1rem;
  line-height: 1.19;
  font-weight: 500;
  text-align: center;
  padding: 0.75rem 0.625rem;
}

.grey-button {
  color: $grey-800;
  width: 100%;
  border: 1px solid $grey-100;
  @include border-radius(4px);
  background-color: $grey-100;
  font-size: 0.875rem;
  padding: 0.625rem;
}
