@import "../../../../Theme/Components/All";

.checkout-footer {
  position: relative;
  padding: 1rem;
  background-color: $white;
  border-top: 2px solid $grey-400;
  bottom: 0;

  @include breakpoint(medium) {
    display: flex;
    flex-direction: row-reverse;
  }

  @include breakpoint(large) {
    background-color: initial;
    border: none;
    width: 100%;
    display: block;
    position: relative;
  }

  &__next-step-button, &__place-order-button {
    height: 3rem;
    font-size: 1rem !important;

    @include breakpoint(medium) {
      margin-left: 1.5rem !important;
    }

    @include breakpoint(large) {
      margin: 0 !important;
    }
  }

  &__back-to-store-button, &__back-to-cart-button {
    height: 3rem;
    margin-top: 1rem !important;
    font-size: 1rem !important;
    display: none !important;

    @include breakpoint(medium) {
      display: initial !important;
      margin-top: 0 !important;
    }

    @include breakpoint(large) {
      display: initial !important;
      margin-top: 1rem !important;
    }
  }

  .order-totals-container {
    width: 100%;
    max-width: 340px;
    border-top: 1px solid $grey-200;
    margin-right: 1rem;
    align-self: flex-end;

    .order-totals {
      border-bottom: 1px solid $grey-200;
      display: flex;
      flex-grow: 4;
      align-items: center;

      .totals-value {
        padding: 0 10px;
        width: 100%;
        text-align: right;
      }

      &.order-totals-quantity {
        .total-quantity-value {
          padding: 0 10px;
          color: $cin7-b2b-items-label;
          width: 100%;
          text-align: right;
        }
      }
    }

    .order-totals-price-container {
      flex-grow: 3;

      .order-totals-price-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $grey-200;
        padding: 8px 0;

        .total-price-value {
          &.grand-total {
            font-size: 1.25rem;
            font-weight: bold;
          }
        }
      }
    }

    .totals-label {
      font-size: 0.75rem;
      text-transform: uppercase;
      color: $cin7-goback-label;
    }

    .totals-value {
      font-size: 1rem;
      color: $cin7-default-font-color;
    }
  }
}
