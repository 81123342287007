@import "../../../../Theme/Components/All";

.edit-address-title {
  width: 100%;
  font-size: 1.25rem;
  margin-bottom: 0.9375rem;
}

.edit-address-container {
  width: 100%;
  margin-bottom: 1.125rem;
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .ui.input.cin7-input-top,
  .form-group {
    width: 100%;
    margin: 0.5rem 0;

    .ui.input.cin7-input-top-field {
      width: 100%;
    }
  }

  .country-address-label {
    color: $grey-800;
    line-height: 20px;
  }
}

.action-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .address-action-clear {
    margin-right: 0.625rem;
    padding: 0.3125rem 1.25rem;
  }

  .cin7-button.address-action-save {
    padding: 0.3125rem 1.25rem;
  }
}
