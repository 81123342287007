@import "../../../../Theme/Components/All";

.contact-us-footer {
    float: left;
    clear: both;
    width: 100%;
    margin: 2rem 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;

    .default-button {
        font-weight: 400;
    }
}

.contact-us-horizontal-line {
    width: 100%;
    border-top: 1px solid #D3D3D3;
}


.contact-us-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    padding-bottom: 16px;
}