@import "../../../../Theme/Components/All";

#Invoice-Particular-Panel {
  color: $black;
  margin-top: 1.5rem;

  .panel-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    font-size: 0.875rem;

    .panel-content-group {
      float: left;
      clear: both;
      width: 100%;

      .panel-group-label {
        margin-bottom: 0.5rem;
        font-weight: bold;
      }

      .panel-group-field {
        width: 100%;
      }
    }
  }
}
