@import "./../../../Theme/Components/All";

#Order-History-Container {
  display: flex;
  justify-content: center;

  .order-history-content {
    width: 100%;
    margin: 1rem 0.625rem;
    background-color: $white;
    border: 1px solid $grey-200;
    border-radius: 4px;
    padding: 0.625rem;
    font-size: 0.875rem;
    overflow: auto;

    @include breakpoint(small) {
      margin: 1rem;
      padding: 1rem;
    }

    @include breakpoint(medium) {
      max-width: 740px;
      margin: 1.5rem;
      padding: 2.5rem;
    }

    @include breakpoint(large) {
      max-width: 1250px;
    }
  }
}
