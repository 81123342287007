@import "./../../../../Theme/Components/All";

#Product-Options-Container {
  width: 100%;
  .product-option-details {
    padding-bottom: 0.9375rem;

    .product-option-details-container {
      background-color: $white;
      color: $black;
      border: 1px solid $grey-200;
      box-shadow: $primary-container-shadow;
      border-radius: 4px;

      .product-option-details-tile {
        display: flex;
        border-bottom: solid 1px $grey-200;
        padding: 0.3125rem 0.625rem;

        .product-option-image-container {
          min-width: 45px;
          max-width: 45px;
          position: relative;

          @include breakpoint(medium) {
            min-width: 55px;
            max-width: 55px;
          }

          @include breakpoint(large) {
            min-width: 75px;
            max-width: 75px;
          }

          &.clickable {
            cursor: pointer;
          }
        }

        .product-option-detail-container {
          width: 100%;
          display: contents;

          @include breakpoint(small) {
            display: flex;
            justify-content: space-between;
          }

          &.sizes {
            display: flex;
            cursor: pointer;
          }

          .product-option-detail {
            flex-grow: 2;
            padding: 0 10px;
            width: 30%;

            .product-option-title {
              font-size: 1.25rem;
              color: $cin7-goback-label;
              font-weight: 600;
              min-height: 1.5rem;
            }

            .option-info-container {
              display: flex;
              justify-content: space-between;

              .option-info-details {
                .product-option-code {
                  font-size: 0.75rem;
                  color: $grey-600;
                }

                & .cin7-tooltip-target {
                  & > .hover-text {
                    font-weight: bold;
                    color: $c21;
                    cursor: default;
                  }
                }
              }

              .product-option-quantity {
                font-size: 1.2rem;
                line-height: 1.2;
                font-weight: 500;
                padding: 0.5rem 0.625rem 0 0.625rem;
                color: $cin7-b2b-items-label;
              }
            }
          }
        }

        .product-option-action-container {
          display: flex;
          justify-content: flex-end;

          @include breakpoint(small) {
            display: inline-flex;
          }

          .product-option-arrow-right {
            align-self: center;
            @include global-transition($global-float-label-transition-slow);
            cursor: pointer;

            path {
              @include global-transition($global-float-label-transition-medium);
            }

            @media (hover: hover) {
              &:hover path {
                stroke: $cin7-b2b-items-label !important;
              }
            }

            &:active path {
              stroke: $grey-400 !important;
            }
          }
        }
      }

      .product-option-details-content {
        max-height: 0;
        overflow: hidden;

        .option-details-inner {
          padding: 0.6rem 0.625rem;

          .inner-option-title {
            font-size: 0.875rem;
            font-weight: bold;
            color: #373839;
          }

          .option-details-inner-child {
            padding: 0.6rem;

            .option-details-inner-child-container {
              display: flex;
              padding: 0.625rem 0;
              border-bottom: 1px solid $grey-200;
              align-items: center;

              .option-details-inner-child-details {
                flex-grow: 2;

                .inner-child-option-title {
                  font-size: 1rem;
                  font-weight: 600;
                  color: $cin7-goback-label;
                }

                .option-sku {
                  color: $grey-600;
                  font-size: 0.75rem;
                }

                .product-details-option-UOM-unit-price {
                  font-size: 0.8rem;
                  font-weight: 400;
                  display: -webkit-box;
                }
              }
            }
          }
        }
      }

      &.active {
        .product-option-arrow-right {
          @include global-transform(rotate3d(0, 0, 1, 180deg));
        }

        .product-option-details-content {
          max-height: none;
        }
      }
    }
  }
}
