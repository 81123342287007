@import "./../../../Theme/Components/Colors";
@import "./../../../Theme/Components/Mixins";

#Global-Return-Panel {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 2.8125rem;
  justify-content: right;
  background: $white;
  z-index: 90;
  border-bottom: 1px solid #eeeeee;
  color: $theme-primary;

  .close-icon {
    padding: 0.625rem 0.625rem;
    display: flex;
    align-items: center;
    
  color: $theme-primary;

    &:hover {
      cursor: pointer;
    }

    .close-label {
      font-size: 1.05rem;
      font-weight: 500;
    }
  }
}
