@import './../../../../Theme/Components/All';

#Product-Not-Found-Container {
    text-align: center;
    position: relative;
    top: 20%;
    @include global-transform(translate(0, -20%));

    .product-not-found-message {
        color: $grey-700;
        font-size: 3.8rem;
    }
}