@import "../../../../Theme/Components/All";

.promotions-list-container {
  max-height: 12rem;
  overflow-y: auto;
  width: 100%;

  & .promotion-description {
    padding: 0.2rem 0;

    &.header {
      font-weight: 600;
    }

    & > span > .highlight {
      
      
      color: $c21;
      &.danger {
        color: $c13;
      }
    }
  }
}
