@import "./../../../../Theme/Components/All";

.custom-homepage-content {
    width: 100%;
    overflow-y: scroll;
    background-color: $grey-000;
    z-index: 50;

    .custom-homepage-content-inner {
        margin: 0 auto;
        max-width: 1200px;
        padding: 1rem;
    }

    .homepage-banner-container {
        background-color: $white;
        margin: 1rem 0;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100px;

        @include breakpoint(small) {
            min-height: 150px;
        }

        @include breakpoint(large) {
            min-height: 230px;
        }

        a {
            width: 100%;
            height: 100%;
        }

        img {
            max-width: 100%;
            max-height: 600px;
        }
    }

    .homepage-categories-container {
        padding: 1rem 0;

        h2 {
            text-align: center;
            margin-bottom: 1rem;
            font-size: 2rem;
            font-weight: 500;
            color: #333333;
        }

        .categories-container {
            display: grid;
            margin: auto -0.5rem;

            @include breakpoint(small) {
                grid-template-columns: repeat(1, 1fr);
            }

            @include breakpoint(medium) {
                grid-template-columns: repeat(2, 1fr);
            }

            @include breakpoint(large) {
                grid-template-columns: repeat(3, 1fr);
            }

            .category-tile {
                background-color: $white;
                margin: 0.5rem;
                padding: 1rem;
                border-radius: 6px;

                .category-image-overlay {
                    opacity: 1;
                    display: flex;
                    width: 100%;
                    @include global-transition($global-float-label-transition-medium);
                    height: 280px;

                    @include breakpoint(large) {
                        height: 380px;
                    }
                }

                &:hover {
                    cursor: pointer;
                }

                &:hover .category-image-overlay {
                    opacity: 0.8;
                }

                img {
                    max-width: 100%;
                    max-height: 100%;
                    margin: auto;
                }

                svg {
                    width: 100%;
                    height: 100%;
                    max-width: 220px;
                    max-height: 220px;
                }

                .category-title {
                    text-align: center;
                    font-weight: 400;
                    padding-top: 1rem;
                    font-size: 1.5rem;
                }
            }
        }
    }
}