@import "./../../../../Theme/Components/All";

#Products-List-Brand-Filter {
  @include global-transition($global-side-nav-transition);

  @include breakpoint(medium) {
    height: auto;
    max-width: 275px;
  }

  .input-icon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }

  &.active {
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .products-filter {
    background-color: $white;
    padding: 1.5rem;
    height: 100%;
    h2 {
      font-weight: 600;
      font-size: 1.5rem;
    }

    .filter-close-icon {
      height: 1.8rem;
      width: 1.8rem;
      position: absolute;
      right: 1.3rem;
      top: 1.5rem;
      cursor: pointer;
    }

    .brand-filter {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .filter-action-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1rem 0 0;

      .filter-action-clear {
      }

      .filter-action-apply,
      .filter-action-clear {
        font-size: 1rem;
        font-weight: 500;
        width: 100%;
        height: 40px;
        margin-bottom: 0.5rem;
      }
    }

    .filters-heading {
      font-size: 0.75rem;
      color: $grey-700;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      padding: 0.625rem 0;
      overflow: hidden;

      span {
        width: 100%;
        margin-left: 0.5rem;
        border-bottom: 1px solid $grey-500;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 2px;
      }
    }

    .cin7-collapse-item {
      border-bottom: 1px solid $grey-500;
      padding: 8px;
      margin: 0;
    }

    .cin7-collapse-header {
      font-size: 0.875rem;
      color: $grey-800;
      font-weight: bold;
    }

    .no-filter-available {
      padding: 0.625rem 0;
      font-size: 1.625rem;
    }

    .brand-search-box {
      margin: 0 0 0.625rem 0;
      width: 100%;

      .input {
        box-shadow: none;
        width: 100%;
        height: 100%;

        @include breakpoint(medium) {
          input {
            width: 100px;
          }
        }
      }
    }

    .ui.input.cin7-input-left.search > .cin7-input-left-field > .cin7-clickablewrapper {
      width: auto;
      padding: 5px;
      display: flex;
    }

    .cin7-button.cin7-button-primary:focus,
    .cin7-button.cin7-button-secondary:focus,
    .cin7-button.cin7-button-tertiary:focus {
      box-shadow: none;
    }

    .ui.checkbox.cin7-checkbox {
      margin: 0.375rem 0;
      min-height: auto;

      @include breakpoint(large) {
        margin: 0.15625rem 0;
      }

      @include breakpoint(extraLarge) {
        margin: initial;
      }

      label {
        line-height: normal;

        &:after {
          line-height: 20px;

          @media (max-width: 767px) and (min-width: 375px) {
            line-height: 24px;
          }

          @media (max-width: 1023px) and (min-width: 768px) {
            line-height: 28px;
          }
        }
      }

      input:focus ~ label:before {
        box-shadow: none;
      }
    }

    .brands-list {
      display: flex;
      flex-direction: column;
      padding: 0.625rem 0;
      overflow-y: auto;

      &::-webkit-scrollbar-thumb {
        background-color: $grey-400;
        border: 3px solid transparent;
        border-radius: 0.625rem;
        background-clip: content-box;
      }

      &::-webkit-scrollbar-track {
        background-color: $grey-000;
      }

      &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
      }
    }
  }
}

#Filter-Sidebar {
  width: 100%;
  height: calc(100% - 80px);
  background-color: $white;
  position: absolute;
  right: -100%;
  z-index: 111;
  overflow-x: hidden;
  @include global-transition($global-side-nav-transition);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);

  @include breakpoint(medium) {
    right: -275px;
    max-width: 275px;
  }

  &.active {
    position: fixed;
    right: 0;
  }

  .nav-content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
  }
}
