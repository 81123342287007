@import "./../../../../Theme/Components/All";

#Product-Info-Card {
  width: 100%;
  font-size: 0.875rem;
  border-bottom: 2px solid $grey-300;
  margin-bottom: 0.8rem;

  .product-info-row {
    width: 100%;
    font-family: $calibre-font-face;
    padding-bottom: 0.8rem;
    display: flex;

    .product-info-brand {
      color: $grey-700;
      line-height: 20px;
    }

    .product-info-name {
      font-size: 2rem;
      font-weight: 500;
      color: #303030;
      font-family: $calibre-font-face;
      line-height: 25px;
      letter-spacing: -0.2px;
    }

    .product-info-price {
      color: $grey-700;
      font-size: 1rem;
      line-height: 20px;
    }

    .product-info-sku {
      color: $grey-600;
    }
  }
}
