@import "../../../../Theme/Components/Mixins";

.upload-bulk-order-modal {
  .upload-bulk-order-modal__cancel-button {
    min-width: 120px;
  }
  .cin7-modal__content {
    max-width: 800px;
  }

  .cin7-modal__box {
    align-self: initial;
  }

  &__content > :not(:last-child) {
    margin-bottom: 1rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    @include breakpoint(medium) {
      flex-direction: row;
    }
  }

  &__header-text {
    @include breakpoint(medium) {
      margin-right: 0.5rem;
    }
  }

  &__header-actions {
    display: flex;

    .cin7-button {
      width: 100%;
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
      @include breakpoint(medium) {
        width: 140px;
      }
    }
  }

  &__prepare-order {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__prepare-order-instructions,
  &__upload-order-instructions {
    max-width: 600px;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    @include breakpoint(medium) {
      margin-bottom: 1rem;
    }
  }

  &__download-template-button.cin7-button {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint(medium) {
      width: auto;
      min-width: 288px;
    }
  }

  &__download-template-button {
    width: 100%;
  }

  .cin7-file-uploader__upload-button > .cin7-button {
    min-width: 150px;
  }

  &__upload-message {
    @include breakpoint(medium) {
      display: none;
    }
  }

  &__upload-drag-and-drop-message {
    display: none;

    @include breakpoint(medium) {
      display: block;
    }
  }

  &__upload-order > :not(:last-child) {
    margin-bottom: 1rem;
  }
}
