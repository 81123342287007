@import "../../../../Theme/Components/All";

.order-option-notes {
  width: 100%;
  
  &__note-header {
    font-weight: 700;
    font-size: 0.75rem;
  }

  &__note {
    font-size: 0.875rem;
    line-height: 1rem;
    word-break: break-word;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 0.375rem 0.5rem 0.5rem;
    margin-bottom: 0.5rem;

    @include breakpoint(medium) {
      margin: 0;
    }
  }
}
