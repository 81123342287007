@import "../../../../Theme/Components/Mixins";

.issues-card {
  &--warning {
    background: rgba(255, 165, 0, 0.03);
    border: 2px solid orange;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;

    .issues-card__issues-list {
      & > li {
        color: orange;
      }
    }

    .issues-card__issues-title.cin7-typography {
      color: orange;
    }
  }

  &--error {
    background: rgba(255, 0, 0, 0.03);
    border: 2px solid #c42651;
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    @include breakpoint(medium) {
      flex-direction: row;
      justify-content: space-between;
    }

    .issues-card__issues-list {
      & > li {
        color: #c42651;
      }
    }

    .issues-card__issues-title.cin7-typography {
      color: #c42651;
    }
  }

  &__issues {
    margin-bottom: 1rem;
    margin-right: 0.5rem;

    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }

  &__issues-list {
    padding-inline-start: 1rem;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    & > li {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
}
