@import "../../../../Theme/Components/All";

#Product-Details-Content {
  background-color: #f5f5f4;
  float: left;
  clear: both;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .product-details-inner {
    max-width: 1280px;
    margin: auto;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 123px);

    @include breakpoint(medium) {
      flex-direction: row;
    }

    #Product-Details-Image-Column {
      position: relative;
      color: $black;
      border-radius: 4px;
      margin: 1rem 1rem 0 1rem;
      height: fit-content;

      @include breakpoint(medium) {
        max-width: 285px;
        width: 100%;
        margin: 1rem;
      }

      @include breakpoint(large) {
        max-width: 345px;
      }

      @include breakpoint(extraLarge) {
        max-width: 400px;
      }

      .product-info-container {
        width: 100%;
        padding: 0 0.9375rem 0.9375rem;
        border-bottom: 2px solid $white;

        @include breakpoint(medium) {
          padding: 0;
          border-bottom: none;
        }
      }

      .product-details-content {
        width: 100%;

        &::-webkit-scrollbar-thumb {
          background-color: $grey-400;
          border: 3px solid transparent;
          border-radius: 0.625rem;
          background-clip: content-box;
        }

        &::-webkit-scrollbar-track {
          background-color: $grey-000;
        }

        &::-webkit-scrollbar {
          width: 12px;
          height: 12px;
        }

        @include breakpoint(medium) {
          border-bottom: none;
        }
      }
    }

    #Product-Details-Options-Column {
      margin: 1rem;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @include breakpoint(medium) {
        margin: 1rem 1rem 0 0;
        width: 100%;
      }
    }
  }
}

.product-details-sticky-footer-mobile {
  min-height: 84px;
  height: 84px;
  background: #ffffff;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 0px 0px;
  position: sticky;
  bottom: -1px;
  display: block;
  @include breakpoint(medium) {
    display: none;
  }
}

.itemTotals {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1.2rem 0.5rem;
}

.product-details-sticky-footer-desktop {
  height: 64px;
  min-height: 64px;
  background: #ffffff;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 0px 0px;
  position: sticky;
  bottom: 0;
  display: none;
  @include breakpoint(medium) {
    display: block;
  }
}

.sticky-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  @include breakpoint(medium) {
    height: 100%;
  }
  .default-button {
    margin-left: 0.5rem;
    @include breakpoint(medium) {
      margin-right: 0.75rem;
    }
    &.filled {
      width: 50%;
    }
  }
}
