@import "./../../../Theme/Components/Colors";
@import "./../../../Theme/Components/Mixins";

.actions-menu {
  width: 100%;
  margin: 0;
  font-size: 1.05rem;
  font-weight: 500;
  color: $theme-primary;
  background: none;
  border: none;
  outline: none;
  padding: 0.625rem 1rem;

  &.no-actions {
    width: 150px;
  }

  .actions-icon-container {
    display: flex;
    justify-content: flex-end;

    .actions-icon {
      padding-left: 1rem;
      
      &:hover {
        cursor: pointer;
      }
    }
  }

  .dropdown-menu-body {
    position: absolute;
    right: 1rem;
    background-color: $white;
    border: 1px solid $grey-400;
    border-radius: 2px;
    width: 260px;
    z-index: 101;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.05), 2px 4px 8px rgba(0, 0, 0, 0.05);
    padding: 0.8rem 0;
    

    button {
      width: 100%;
      margin: 0;
      font-size: 1.05rem;
      font-weight: 500;
      color: $theme-primary;
      background: none;
      border: none;
      outline: none;
      padding: 0.75rem 1rem;
      text-align: left;

      &:hover {
        background-color: $grey-000;
      }
    }
  }

  .dropdown-cover {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
}
