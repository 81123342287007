@import "../../../../Theme/Components/All";

.cin7-modal.product-details {
  .cin7-modal__content {
    padding: 0;
  }

  .cin7-modal__box {
    width: 100%;
    margin: 1rem;
    height: calc(100% - 1.25rem);

    @include breakpoint(minimumRes) {
      margin: 0.625rem;
      height: calc(100% - 1.25rem);
    }

    @include breakpoint(medium) {
      margin: 1.4375rem;
      height: calc(100% - 4.6rem);
    }

    @include breakpoint(large) {
      margin: 2.8125rem;
      height: calc(100% - 5.625rem);
    }

    @media (min-width: 1580px) {
      margin: 2.8125rem auto;
      max-width: 1480px;
    }
  }
}

.product-details-modal-content {
  width: 100%;
  height: 100%;
  background-color: #f5f5f4;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 0 !important;

  .loading-container {
    margin-top: auto;
    margin-bottom: auto;
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #Product-Details-Content {
    max-height: 87vh;

    &::-webkit-scrollbar-thumb {
      background-color: $grey-400;
      border: 3px solid transparent;
      border-radius: 0.625rem;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-track {
      background-color: $grey-000;
    }

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }
  }
}
