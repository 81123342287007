@import "./../../../Theme/Components/All";

#Products-List-Container {
  background-color: $grey-000;
  float: left;
  clear: both;
  width: 100%;
  z-index: 99;
  overflow-y: auto;
  padding-bottom: 1rem;

  &.product-detail-open {
    overflow: hidden;

    #Products-List-Items {
      float: left;
      clear: both;
      width: 100%;

      .product-details-modal {
        display: block;
      }

      .infinite-scroll-component__outerdiv {
        float: left;
        clear: both;
        width: 100%;
      }
    }
  }

  #Products-List-Items {
    .product-details-modal {
      display: none;
    }
  }
}
