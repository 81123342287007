@import "../../../../Theme/Components/All";

.address-panel {
  color: $black;
  margin-top: 1.5rem;

  &__panel-heading {
    display: flex;
  }

  &__address-title {
    font-size: 1.125em;
    font-weight: bold;
    padding-bottom: 1rem;
  }

  &__address-inner-content {
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    padding: 1rem;
    min-height: 100px;
    display: grid;
    grid-template-columns: 70px auto;
    grid-template-areas: 
      "Icon Address"
      "Edit Edit";

    @include breakpoint(small) {
      display: grid;
      grid-template-columns: 70px auto 140px;
      grid-template-areas: 
      "Icon Address Edit";
      align-items: center;
    }
  }

  &__address-icon-container {
    grid-area: Icon;
    display: flex;
    align-items: center;

    @include breakpoint(small) {
      text-align: initial;
    }
  }

  &__address-container {
    grid-area: Address;
  }

  &__address-item,
    &__address-company,
    &__address-first-name,
    &__address-last-name {
      font-size: 0.875rem;
    }

  &__address-change {
    grid-area: Edit;
    padding-top: 1rem;
    display: flex;
    justify-content: center;

    @include breakpoint(small) {
      padding: 0;
    }
  }

  &__address-change-button {
    font-size: 1rem !important;
  }
}
