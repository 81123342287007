@import "../../../../Theme/Components/Mixins";

.confirm-bulk-order-modal {
  .cin7-modal__content {
    max-width: 800px;
  }

  .cin7-modal__box {
    align-self: initial;
  }
  .issues-card__action {
    min-width: 115px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  &__content > :not(:last-child) {
    margin-bottom: 1rem;
  }

  &__reupload-button {
    min-width: 120px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem;

    @include breakpoint(medium) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__header-text {
    @include breakpoint(medium) {
      margin-right: 0.5rem;
    }
  }

  .confirm-bulk-order-modal__cancel-button {
    width: 100%;
    @include breakpoint(medium) {
      width: auto;
      min-width: 120px;
    }
  }

  &__prompt.typography {
    margin-bottom: 1rem;
  }

  &__actions > :not(:last-child) {
    margin-bottom: 1rem;
  }
}
