@import "./../../../Theme/Components/Colors";
@import "./../../../Theme/Components/Mixins";

.go-back-button {
  width: 100%;
  max-width: 100px;
  margin: 0;
  font-size: 0.875rem;
  color: $cin7-goback-label;
  background: none;
  border: none;
  outline: none;
  padding: 0.625rem 1rem;
  display: flex;
  align-items: center;

  @include breakpoint(medium) {
    max-width: 150px;
  }

  .back-icon {
    display: flex;
    align-items: center;
  }

  &.add-border {
    border-bottom: 1px solid $grey;
  }

  .link-text {
    line-height: 1.6;
  }
}
