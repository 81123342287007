.empty-cart {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;

  &__message {
    max-width: 300px;
  }

  &__actions {
    display: flex;

    .empty-cart__return-homepage-button {
      margin-right: 0.5rem;
    }
    .empty-cart__browse-products-button {
      margin-right: 0.5rem;
    }
  }
}
