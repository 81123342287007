@import "../../../../Theme/Components/All";

.reorder-modal {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__content > :not(:last-child) {
    margin-bottom: 1rem;
    min-width: 200px;

    @include breakpoint(small) {
      min-width: 350px;
    }
  }

  &__header-cancel-button {
    min-width: 140px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__action-buttons {
    margin-top: auto;
    width: 100%;
    & > * {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 0.875rem;
      }
    }
  }

  &__complete-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 0.5rem;

    & > :not(:last-child) {
      margin-bottom: 1rem;
    }

    .info-icon {
      svg {
        height: 48px;
        width: 48px;
        transform: rotate(180deg);
      }
    }
  }
}
