@import "./../../../../Theme/Components/All";

.clear-bg {
  position: absolute;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: $black, $alpha: 0);
  @include global-transition($global-side-nav-transition);
}
