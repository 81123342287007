@import "../../../Theme/Components/All";

.html-block {
  width: 100%;
  font-family: $calibre-font-face;
  color: $grey-800;
  font-size: 0.875rem;
  line-height: 1.25;

  p {
    font-family: $calibre-font-face;
    color: $grey-800;
    font-size: 0.875rem;
    line-height: 1.25;

    a,
    a:visited {
      color: $cin7-sku-blue-label;
    }
  }

  ul,
  ol {
    a,
    a:visited {
      color: $cin7-sku-blue-label;
    }
  }

  iframe {
    max-width: 100%;

    @include breakpoint(extraSmall) {
      height: auto;
    }
  }

  &--less {
    color: $grey-800;
    overflow-y: hidden;
    max-height: 3.3rem;
  }
}