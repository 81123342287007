@import "./../../../../Theme/Components/All";

.stock-indicator {
  color: $cin7-product-stock-label;
  margin-right: 0.5rem;
  font-weight: 400;

  &.out-of-stock {
    color: #d63d00;
  }

  &.stock-warning {
    color: #e29200;
  }

  &.incoming-stock {
    color: #7A5801;
  }
}
