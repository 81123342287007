@import "./../../../../Theme/Components/All";

.product-details-option-price {
  font-size: 1rem;
  color: $grey-700;
  min-height: 1.3rem;

  .special-price {
    color: #f52a2a;
  }

  .original-price {
    text-decoration: line-through;
    padding-left: 0.3125rem;
  }
}
