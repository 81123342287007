@import "./../../../Theme/Components/All";

#Order-Completion-Container {
  width: 100%;
  height: calc(100% - 0.625rem);
  position: relative;
  background: $grey-000;
  overflow-y: auto;

  .order-completion-content {
    background-color: $white;
    max-width: 90%;
    margin: 10% auto;
    text-align: center;
    padding: 1.25rem;
    border: 1px solid $grey-200;
    border-radius: 4px;
    box-shadow: $primary-container-shadow;

    @include breakpoint(medium) {
      max-width: 80%;
      padding: 2.5rem;
    }

    @include breakpoint(large) {
      margin: 5% auto;
    }

    .order-completion-icon {
      padding: 0.625rem 0;

      svg {
        width: 6.6rem;
        height: 6.6rem;
      }
    }

    .order-completion-title {
      font-size: 1.625rem;
      color: $cin7-product-stock-label;
    }

    .order-completion-body {
      margin: 1.875rem 0;
      font-size: 1.25rem;
      color: $grey-600;

      .order-reference {
        color: $cin7-default-font-color;
        font-weight: bold;
      }

      .order-email-text {
        margin-top: 0.9375rem;
      }
    }

    .order-completion-action {
      margin: 3.125rem 0 1.875rem;

      a {
        color: $white;
        background-color: $cin7-b2b-items-label;
        font-size: 0.875rem;
        border-radius: 4px;
        padding: 12px 34px;
      }
    }
  }
}
