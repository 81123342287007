.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .logo-image {
        svg {
            max-width: 220px;
        }
    }

    svg {
        width: 100%;
        height: 100%;
        max-width: 120px;
    }

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto;
        height: auto;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
    }
}