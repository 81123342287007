//Cross browser CSS3 mixins

@mixin box-shadow($left, $top, $radius, $color) {
    box-shadow: $left $top $radius $color;
    -webkit-box-shadow: $left $top $radius $color;
    -moz-box-shadow: $left $top $radius $color;
}

@mixin transition($property, $duration, $easing: linear) {
    transition: $property $duration $easing;
    -webkit-transition: $property $duration $easing;
    -moz-transition: $property $duration $easing;
}

@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
}

@mixin border-radius-x($topleft, $topright, $bottomright, $bottomleft) {
    border-top-left-radius: $topleft;
    border-top-right-radius: $topright;
    border-bottom-right-radius: $bottomright;
    border-bottom-left-radius: $bottomleft;
    -webkit-border-top-left-radius: $topleft;
    -webkit-border-top-right-radius: $topright;
    -webkit-border-bottom-right-radius: $bottomright;
    -webkit-border-bottom-left-radius: $bottomleft;
    -moz-border-radius-topleft: $topleft;
    -moz-border-radius-topright: $topright;
    -moz-border-radius-bottomright: $bottomright;
    -moz-border-radius-bottomleft: $bottomleft;
}

@mixin gradient($color1, $color2) {
    background-color: $color1;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$color1}, endColorstr=#{$color2});
    background-image: radial-gradient(ellipse at top, $color1, $color2);
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($color1), to($color2));
}
@mixin linearGradient($top, $bottom) {
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top, $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, $top 0%,$bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, $top 0%,$bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom, $top 0%,$bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

@mixin global-transition($args...) {
    transition: $args;
    -webkit-transition: $args;
    -moz-transition: $args;
}

@mixin global-transform($args...) {
    transform: $args;
    -webkit-transform: $args;
    -moz-transform: $args;
}

@mixin global-box-shadow($args...) {
    box-shadow: $args;
    -webkit-box-shadow: $args;
    -moz-box-shadow: $args;
}

@mixin global-order($position) {
    order: $position;
    -ms-flex-order: $position;
}

//Breakpoints
//Based on Bootstrap breakpoints - https://getbootstrap.com/docs/4.0/layout/grid/#grid-options
@mixin breakpoint($screen) {
    @if $screen == extraLarge {
        @media (min-width: 1900px) {
            @content;
        }
    }

    @else if $screen == large {
        @media (min-width: 992px) {
            @content;
        }
    }

    @else if $screen == maxLarge {
        @media (max-width: 991px) {
            @content;
        }
    }

    @else if $screen == medium {
        @media (min-width: 768px) {
            @content;
        }
    }

    @else if $screen == maxMedium {
        @media (max-width: 767px) {
            @content;
        }
    }

    @else if $screen == small {
        @media (min-width: 576px) {
            @content;
        }
    }

    @else if $screen == extraSmall {
        @media(max-width: 576px) {
            @content;
        }
    }

    @else if($screen == smallPortrait) {
        @media(min-width: 380px) {
            @content;
        }
    }

    @else if $screen == minimumRes {
        @media(max-width: 320px) {
            @content;
        }
    }
}

//Placeholder Style Text
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

//Disable hover effect on mobile devices
@mixin hover-supported {    
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
}
