//Themes
@import "./../Templates/SideImageTheme";

#Body-Content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: 0;
  padding-top: 80px;
  @include global-transition($global-side-nav-transition);
}
