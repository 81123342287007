@import "./../../../Theme/Components/All";

.confirmation-box-content {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.875rem 1.5625rem 0.875rem 0.75rem;
  position: relative;
  border-radius: 4px;
  border-left: 4px solid #00326b;
  color: $grey-700;
  box-shadow: 0px 0px 40px -10px rgba(0, 0, 0, 0.08), 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
  margin-bottom: 1rem;

  &.success-box,
  &.warning-box,
  &.error-box {
    .confirmation-box-message {
      .main-message {
        font-weight: 700;
      }

      .sub-message {
        float: left;
        clear: both;
      }
    }
  }

  &.info-box {
    border-color: #00326b;

    .confirmation-box-icon {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &.success-box {
    border-color: $cin7-product-stock-label;
  }

  &.error-box {
    border-color: #c42651;
  }

  &.warning-box {
    border-color: #f2c94c;
  }

  .confirmation-box-icon {
    svg {
      max-height: 1.5625rem;
      max-width: 1.5625rem;
    }
  }

  .confirmation-box-message {
    font-size: 0.875rem;
    line-height: 1.29;
    text-align: left;
    margin-left: 0.75rem;

    .main-message {
      float: left;
      clear: both;
    }
  }
}
