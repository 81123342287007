@import "./../../../../Theme/Components/All";

#Product-Gallery-Modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $background-overlay-modal-color;
  }

  .gallery-container {
    position: relative;
    z-index: 101;
    width: 85%;
    height: 85%;
    max-width: 1500px;
    max-height: 1600px;
    background: $white;
    padding: 0.625rem;
    top: 50%;
    left: 50%;
    @include global-transform(translate(-50%, -50%));

    .next-image,
    .prev-image {
      position: absolute;
      top: 0;
      width: 55px;
      height: 100%;
      z-index: 102;
      cursor: pointer;

      svg {
        position: relative;
        top: 50%;
        left: 50%;
        @include global-transform(translate(-50%, -50%));
      }
    }

    .next-image {
      right: 0;
    }

    .prev-image {
      left: 0;
    }

    .gallery-close-container {
      position: absolute;
      top: -45px;
      right: -25px;
      cursor: pointer;

      @include breakpoint(minimumRes) {
        right: 0;
      }

      @include breakpoint(small) {
        right: -45px;
      }

      svg {
        width: 45px;
        height: 45px;
      }
    }

    .gallery-list-content {
      display: flex;
      width: 100%;
      height: 100%;
      position: relative;

      .gallery-image-modal {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto;
        height: auto;
        margin: auto;
        max-width: 100%;
        max-height: 100%;

        &.active {
          opacity: 1;
          @include global-transition(opacity 0.6s ease-in);
        }

        &.inactive {
          opacity: 0;
          height: 0;
          @include global-transition(opacity 0.6s ease-out);
        }
      }
    }
  }
}
