@import "./../../../Theme/Components/Colors";
@import "./../../../Theme/Components/All";
#contain {
  background-color: #f8f9fb;
  height: 100%;
  width: 100%;
  z-index: 99;
  overflow-y: auto;
  @include breakpoint(medium) {
    padding: 0 0.5rem;
  }
}

hr {
  margin: 1rem 0.5rem;
}

h1.category-title {
  font-weight: 500;
  font-size: 2rem;
  letter-spacing: -0.3px;
}

.breadcrumbs {
  color: $theme-primary;
  & li {
    float: left;
    padding-left: 0.25rem;
    font-weight: 400;
  }
  &.last {
    color: black;
  }
  &:hover {
    cursor: pointer;
    color: $cin7-blue;
  }
}

ol.crumb-list {
  list-style: none;
  padding-inline-start: 0;
  margin-bottom: 0;
}

.category-buttons {
  display: block;
  @include breakpoint(medium) {
    display: flex;
    justify-content: flex-end;
  }
  button {
    margin: 0 0.25rem 0 0;
    &.filled {
      margin: 0 0.5rem 0.25rem 0;
    }
  }
}

.category-header {
  width: 100%;
  margin-top: 1.25rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @include breakpoint(medium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.title-container {
  display: block;
  width: 100%;
}

.button-icon-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.switching {
    width: 120px;
  }
  i.icon {
    font: normal normal normal 1.5rem FontAwesome,sans-serif;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: $theme-primary;
    padding-right: 0.5rem;
    width: 32px;
    &.thick {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}

.chips {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  padding: 0.5rem;
  margin: 0.5rem 0.5rem;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  justify-content: space-between;
  align-items: center;
  @include breakpoint(medium) {
    flex-wrap: nowrap;
  }

  hr {
    width: 100%;
    margin-bottom: 0.5rem;
    @include breakpoint(medium) {
      display: none;
    }
  }

  .chip-box {
    padding: 0 0.5rem;
    display: block;
    @include breakpoint(medium) {
      padding: 0;
      display: flex;
    }
    i.icon {
      color: $black;
    }
  }

  .clear-box {
    padding: 0.5rem 0.5rem;
    width: 100%;
    @include breakpoint(medium) {
      padding: 0 0.5rem;
      width: initial;
    }

    button.clear-button {
      width: 100%;
      @include breakpoint(medium) {
        border: none;
        height: initial;
        min-width: auto;
        padding: 0;
        &:hover {
          cursor: pointer;
          text-decoration: none;
          background: none;
          color: $theme-primary;
        }
      }
    }
  }

  .label {
    font-weight: 600;
    padding: 0 1rem 0.25rem 0;
    width: 100%;
    vertical-align: middle;
    @include breakpoint(medium) {
      padding-bottom: 0;
      min-width: fit-content;
      width: auto;
    }
  }

  .chip {
    white-space: nowrap;
    gap: 0.25rem;

    &.show-more {
      cursor: pointer;
    }
    &:not(:last-child) {
      margin: 0 0.5rem 0.25rem 0;
    }
    .chip__label {
      margin: 0;
    }
  }
}

.filter-change-view-container {
  display: block;
  @include breakpoint(medium) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
