@import "../../../../Theme/Components/All";

.delivery {

    &__delivery-container {
        background: #FFFFFF;
        box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.07);
        border-radius: 8px;
        margin: 1rem;
        padding: 1rem;

        @include breakpoint(medium) {
            padding: 1.5rem;
        }
    }

    &__delivery-inner-content {
        margin-top: 0.75rem;
        border-top: 1px solid #E0E0E0;
    }
}