@import "../../../../Theme/Components/All";

#Freight-Panel {
  color: $black;
  margin-top: 1.5rem;

  .freight-title {
    font-size: 1.125em;
    font-weight: bold;
    padding-bottom: 1rem;
  }

  .freight-validation-message {
    color: #C42651;
    font-size: 0.875rem;
    padding-left: 1rem;
  }

  .freight-options-container {
    border: 1px solid #D3D3D3;
    border-radius: 8px;

    &.validation-message {
      border-color: #C42651;
    }
  }

  .freight-zone-option {
    padding: 0.125rem 1rem;
    border-bottom: 1px solid #D3D3D3;
    width: 100%;
    
    &:last-of-type {
      border: none;
    }

    .freight-tier-option {
      border-top: 1px solid #D3D3D3;
      padding-left: 2.875rem;
    }
  }

  .freight-tiers-container {
    margin: 0 -1rem;
  }

  .freight-inner-content {
    padding: 1rem;
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    display: grid;
    align-items: center;
    grid-template-columns: 70px auto;

    .freight-option {

      .freight-label {
        color: $theme-primary;
        font-weight: 600;
      }
    }
  }
}
