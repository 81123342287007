@import "../../../../Theme/Components/All";

.totals-panel {

  .account-on-hold-container {
    .account-on-hold-banner {
      padding: 1rem 1rem 0;
    }
  }

  .order-totals-content {
    padding: 2rem 1rem 1rem;

    .totals-title {
      font-size: 1.125rem;
      font-weight: 600;
      padding-bottom: 0.5rem;
      display: none;

      @include breakpoint(medium) {
        display: block;
      }
    }

    .order-totals-container {
      border-top: 1px solid $grey-400;

      .order-totals-price-container {
        font-size: 1.125rem;
        color: $cin7-mini-cart-currency-color;

        .order-totals-price-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 0;

          &.subtotal{
            border-top: 1px solid $grey-400;
          }
          
          &.grand-total {
            border-top: 1px solid $grey-400;
            color: $black;
          }

          .total-price-value {
            &.grand-total {
              font-size: 1.25rem;
              font-weight: 600;
              color: $black;
            }
          }
        }
      }

      .totals-label {
        font-weight: 400;

        &.grand-total {
          font-size: 1.25rem;
          font-weight: 600;
        }
      }

      .totals-value {

        &.discount{
          color: $cin7-b2b-discount-label;
        }
      }
    }
  }
}
