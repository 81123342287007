@import './../../../../Theme/Components/All';

.cin7-tooltip.show-arrow:not(.cin7-tooltip-hidden){
    z-index: 100;

    & > .cin7-tooltip-content{
        min-width: 26rem;;
        max-width: 33rem;
        border-color: #D7D7D7;
        padding: 1.5rem;
    }
}