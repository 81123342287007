@import "../../../../Theme/Components/All";

.side-banner-container {
  @include global-order(2);
  display: none;
  visibility: hidden;
  width: 100%;
  height: 100%;
  max-height: 450px;
  position: relative;
  background-color: $white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @include breakpoint(medium) {
    display: block;
    visibility: visible;
  }

  @include breakpoint(large) {
    @include global-order(1);
    max-width: 50%;
    max-height: 100%;
  }

  .side-banner-overlay {
    background: $background-overlay-color;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  .side-banner-text {
    position: absolute;
    font-family: $calibre-font-face;
    width: 100%;
    max-width: 460px;
    top: 50%;
    left: 50%;
    font-size: 1.625rem;
    line-height: 1.48;
    letter-spacing: 10.8px;
    font-weight: 600;
    text-transform: uppercase;
    color: $white;
    text-align: center;
    z-index: 2;
    @include global-transform(translate(-50%, -50%));

    @include breakpoint(large) {
      font-size: 2.1875rem;
      line-height: 1.71;
      letter-spacing: 14px;
      max-width: 300px;
    }
  }
}
